export default {
  navigation: {
    installers: "Installers",
    blog: "Blog",
    help: "Help",
    "about-us": "About us",
    "sign-in": "Sign in",
    "sign-up": "Sign up",
  },

  footer: {
    "products-for-installers": "Products for installers",
    "find-clients": "Find clients",
    administration: "Administration",
    "digital-sales-tools": "Digital sales tools",
    resources: "Resources",
    documentation: "Documentation",
    guide: "Guide",
    blog: "Blog",
    "support-centre": "Support Center",
    partners: "Partners",
    enterprise: "Company",
    home: "Home",
    "about-us": "About us",
    "company-values": "Company Values",
    pricing: "Pricing",
    newsletter: "Subscribe",
    "subscribe-newsletter":
      "Get the latest news and articles to your inbox every month",
    "your-email": "Your email",
    copyright: "2023 © projetsolaire.com. All rights reserved.",
    "terms-and-conditions": "Terms",
    "privacy-policy": "Privacy Policy",
    "contact-us": "Contact",
    press: "Presse",
    technology: "Technologie",
  },

  "reset-password": {
    title: "Recover your password",
    subtitle:
      "Entrez l'adresse email que vous avez utilisée lors de votre inscription, et nous vous enverrons un email automatique pour vous réinitialiser votre mot de passe.",
    "check-your-inbox": "vérifiez votre boîte de réception",
    type: {
      householder: "Householder",
      installer: "Installer",
    },
    success: {
      title: "vérifiez votre boîte de réception",
      description: "",
    },
    callback: {
      title: "réinitialiser votre mot de passe",
      "description-1":
        "« Pour protéger vos informations, il est nécessaire de choisir et d’utiliser des mots de passe robustes, c’est-à-dire difficiles à retrouver à l’aide d’outils automatisés et à deviner par une tierce personne »",
      "description-2": "www.interieur.gouv.fr",
      anssi: "l’Agence Nationale de la Sécurité des Systèmes d’Information",
      password: "Password",
      "confirm-password": "Confirm password",
      "reset-your-pass-word": "Reset password",
    },
    "forgot-password": {
      title: "Forgot your password ?",
      description:
        "Enter the email address you used when you registered your account, and we will send you a secure password reset email.",
      "select-type": "You are ...",
      email: "Email",
      "reset-your-pass-word": "Reset password",
    },
  },

  "sign-in": {
    title: "Welcome Back! ",
    "type-select": "You are ...",
    "having-trouble": "Having trouble signing in?",
    "keep-me-signed-in": "Keep me signed in",
    "dont-have-an-account": "Don't you have an account? ",
    type: {
      householder: "Householder",
      installer: "Installer",
    },
    errors: {
      "account.locked":
        "Account temporarily locked. Please reset your password or contact the account manager. ",
    },
  },

  "sign-up": {
    title:
      "Welcome. Start your <span class='bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-yellow-400'>solar</span> journey today.",
    tc: " By creating an account, you agree to the <a class='underline' href='/help/guides/installers/legal/declaration-de-confidentialite'>terms & conditions</a>, and our <a class='underline' href='/help/guides/installers/legal/declaration-de-confidentialite'>privacy policy</a>.",
    "already-registered": "Already using Projetsolaire?",
    "company-name": "Votre raison sociale",
    "enter-your-company-name": "Entrez votre raison sociale",
    "password-confirm": "Confirmez votre mot de passe",
    type: {
      householder: "Householder",
      installer: "Installer",
    },
    success: {
      title:
        "Bienvenue ! Veuillez consulter votre courrier électronique avant d'accéder au service élance.",
      description:
        'Veuillez consulter votre boîte mail afin de confirmer la création de votre compte. Si vous ne voyez pas l\'email de confirmation tout de suite, veuillez vérifier votre dossier "spam" ou "courrier indésirable" et marquez-le "non-spam". Le message de confirmation s\'y retrouve parfois par erreur. Si vous avez une question n\'hésitez pas à nous contacter par courrier électronique.',
    },
    callback: {
      success: {
        title: "Inscription réussie",
        description: "L'email de votre compte a été validé avec succès",
      },
      invites: {
        installer: {
          title: "Vous êtes invité pour rejoindre votre équipe solaire",
          description:
            "Veuillez confirmer l’invitation pour créer votre compte à vous et obtenir l’accès à l’espace professionnel en ligne.",
        },
        user: {
          title: "",
          description: "",
        },
        lead: {
          title: "",
          description: "",
        },
      },
    },
    password: {
      required: "The password is required",
      "min-length": "The password must contain at least 8 characters",
      "upper-case": "The password must contain at least one uppercase letter",
      "lower-case": "The password must contain at least one lowercase letter",
      symbol:
        "The password must contain at least one special character (@$!%*?&)",
      number: "The password must contain at least one number",
    },
  },

  home: {
    hero: {
      "find-an-installer": "Find an installer",
      "i-am-installer": "I'm an installer",
      title:
        "Helping France go <span class='bg-clip-text text-transparent bg-gradient-to-r from-orange-500 to-yellow-400'>solar</span>",
      subtitle:
        "The easiest way to go solar, from discovering your solar potential to installation day.",
      "learn-more": "Learn more",
      "discover-potential": "Discover your solar potential",
      "watch-video": "Watch the full video (30s)",
    },

    "how-to": {
      title: "How to go solar?",
      description:
        "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur excepteur sint occaecat cupidatat.",
    },

    "why-us": {
      title: "Pourquoi passer au solaire avec nous ?",
      description:
        "Nous vous encadrons la réalisation de votre projet solaire. Notre plateforme communautaire repose sur des principes simples : transparence des offres des maitres d'œuvres, parcours à votre rythme, sans engagements. Le service est gratuit pour les particuliers.",
      features: {
        model: {
          title: "Get a in-depth customised solar model",
          description:
            "We take your real historic consumption data from the grid and combine that with an estimate of your solar production using Lidar to produce a 25 year financial model of your own solar project.",
        },
        installers: {
          title: "Get quotes from trusted installers",
          description:
            "We have a network of installers ready to quote on your project. Benefit from a wide range of options and achieve a better price",
        },
        administration: {
          title: "Take care of your administration",
          description:
            "Let us take care of all your administration as part of the solar installation journey. Track its progress online or on your mobile.",
        },
        support: {
          title: "Support all the way until installation day",
          description:
            "Any questions during your installation journey? We are here for you, we have a dedicated staff ready to answer any questions you may have",
        },
      },
    },
    stats: {
      "our-track-record": "Our track record",
      title: "Weve helped homes all across France go solar",
      description:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
      "stats-value": {
        "3-quotes-guarantee": {
          title: "Entreprises par appel d'offres, moyenne",
          value: "4 propositions",
        },
        "installers-in-network": {
          title: "Installateurs photovoltaïques chez nous",
          value: "100 +",
        },
        "cost-reduction": {
          title: "Réduction sur montant du devis, moyenne",
          value: "40 %",
        },
        "aided-solar-projects": {
          title: "Projets solaires rendus possible",
          value: "3 000 +",
        },
      },
    },

    stories: {
      title: "Discover others solar journey",
      description:
        "Meet our community of households who have gone solar all across france Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum ",
      button: "Discover all stories",
    },

    community: {
      title: "Join our community of solar projects",
      description:
        "We have helped people go solar all across France. Start your solar journey today and join the rest of France on the national solar mission",
    },
    mission: {
      title: "Our mission",
      paragraph1:
        "Our objective is to facilitate, secure and then democratize at scale all the stages of the photovoltaic installation.",
      paragraph2: "Make it easier to go solar",
      paragraph3: "End scams",
      paragraph4: "Massively increase the number of solar installations",
      paragraph5:
        "Projetsolaire.com is therefore the solar project of a family, a local business, a community … it is their solar project, it is her solar project. This is finally your solar project too.",
    },

    cta: {
      title: "Want to discover more?",
      subtitle: "Please book a call on our current site Maison Elance.",
      benefit: {
        benefit1: "Competitive salaries",
        benefit2: "Flexible work hours",
        benefit3: "30 days of paid vacation",
        benefit4: "Annual team retreats",
        benefit5: "Benefits for you and your family",
        benefit6: "A great work environment",
      },
      button: "Discover more",
    },
  },

  installer: {
    services: {
      hero: {
        title: "Helping you grow your installation business",
        paragraph:
          "Cupidatat minim id magna ipsum sint dolor qui. Sunt sit in quis cupidatat mollit aute velit. Et labore commodo nulla aliqua proident mollit ullamco exercitation tempor. Sint aliqua anim nulla sunt mollit id pariatur in voluptate cillum. Eu voluptate tempor esse minim amet fugiat veniam occaecat aliqua.",
        "button-1": "Demander une démo",
        "button-2": "Démarrer maintenant",
      },
      "value-proposition": {
        "everything-you-need": "Everything you need",
        title: "The platform to manage your solar projects",
        paragraph:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis.",
        "key-value": {
          "team-management": {
            title: "Team management",
            description: "bla bla bla",
          },
          "digital-presentations": {
            title: "Digital presentations",
            description: "bla bla bla...",
          },
          administration: {
            title: "Administration",
            description: "Bla bla bla !",
          },
          "quotes-management": {
            title: "Quote management",
            description: "Bla bla bla ?",
          },
          "hardware-management": {
            title: "Hardware management",
            description: "bla bla bla ?!",
          },
          "calendar-management": {
            title: "Calendar management",
            description: "bla bla bla.",
          },
        },
      },
      stats: {
        "why-us": "Why us ?",
        title: "Get support to grow your installation business",
        paragraph:
          "Rhoncus sagittis risus arcu erat lectus bibendum. Ut in adipiscing quis in viverra tristique sem. Ornare feugiat viverra eleifend fusce orci in quis amet. Sit in et vitae tortor, massa. Dapibus laoreet amet lacus nibh integer quis. Eu vulputate diam sit tellus quis at.",
        "number-stats": {
          "founded-in": {
            stat: "2020",
            emphasis: "Founded",
            rest: "during first confinement.",
          },
          "installer-on-platform": {
            stat: "600+",
            emphasis: "Local businesses",
            rest: "with us.",
          },
          servicing: {
            stat: "5000+",
            emphasis: "Administration services",
            rest: "per month.",
          },
          "projects-in-france": {
            stat: "100+ MW",
            emphasis: "Solar projects",
            rest: "made possible.",
          },
        },
      },
      testimonials: {},
      "service-block": {
        title: "The solutions that we propose",
        description: "Explore how to grow your business while saving time.",
        services: {
          "meet-new-customers": "Meet new customers",
          "automate-administration": "Automate the administrative process",
          "explore-digital": "Explore the digital",
        },
      },
      commitments: {
        "why-us": "L'informatique au service du photovoltaïque",
        title: "Nos engagements",
        paragraph:
          "La France est le seul pays en Europe qui a installé moins de kW de panneaux solaires cette année que l’année dernière. Les délais dus aux démarches administratives se comptent en plusieurs mois. Le progrès technique fait qu’une démarche de rupture est nécessaire. Nous vous aidons l'artisanat de France à gagner du temps pour mieux croître.",
        "key-values": {
          value1: {
            title:
              "Faciliter aux professionnels techniques l’exercice de leur cœur de métier.",
            description: "",
          },
          value2: {
            title:
              "Libérer enfin la filière photovoltaïque de l’éco-délinquance.",
            description: "",
          },
          value3: {
            title:
              "Proposer les services numériques de pointe aux artisans pour réduire leurs délais ainsi que coûts de commercialisation.",
            description: "",
          },
          value4: {
            title:
              "Renforcer l’indépendance de la filière par rapport aux grands groupes de sous-traitance et des plateformes contraignantes",
            description: ".",
          },
          value5: {
            title:
              "Valoriser les entreprises locales et le savoir-faire de proximité.",
            description: "",
          },
          value6: {
            title:
              "Réduire la fracture numérique qui se creuse dans notre pays.",
            description: "",
          },
        },
      },

      cta: {
        "start-using": "Start using our platform today.",
        paragraph:
          "Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur commodo do ea.",
        button: "Get started",
        "learn-more": "Learn more",
      },
    },
    "find-new-clients": {
      hero: {
        "discover-new-clients": {
          title: "Découvrir l'installateur de nouveaux clients",
          paragraph:
            "Vous voulez vous impliquer dans d'autres projets ? Nous avons des clients qui attendent de passer au solaire dans toute la France.",
          button: "Get started",
          "learn-more": "Learn more",
        },
      },
      process: {
        step1: {
          title: "Get verified",
          description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        },
        step2: {
          title: "Start browsing projects",
          description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        },
        step3: {
          title: "Quote",
          description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        },
        step4: {
          title: "Install",
          description:
            "Nihil aut nam. Dignissimos a pariatur et quos omnis. Aspernatur asperiores et dolorem dolorem optio voluptate repudiandae.",
        },
      },
      features: {
        "search-local-area": {
          title: "Trouvez des chantiers en ligne",
          paragraph:
            "L'utilisateur choisi le cahier de charges qui correspondra au mieux à l'orientation énergétique du projet solaire. L’utilisateur est accompagné dans son choix grâce à notre étude technico-financière de son installation photovoltaïque. Celle-ci inclut l’évaluation au kWh/m² de l’ensoleillement ainsi que l'analyse horaire de la courbe de charge sur site. L’utilisateur choisi son cahier de charges parmi plusieurs options.",
          button: "En savoir plus",
        },

        "use-your-hardware": {
          title: "Vous choisissez le matériel",
          paragraph:
            "Prenez connaissance du cahier des charges de l'utilisateur pour personaliser au mieux. Renseignez vos modèles de modules et d'onduleurs. La faisabilité technique est validée par vous-même lors de la visite technique obligatoire sur place.",
          button: "",
        },

        "submit-custom-quotes": {
          title: "Chiffrez votre prix",
          paragraph:
            "Fixez librement votre prix et votre acompte en soumettant votre proposition avec votre matériel, prestations et tarifs. Détaillez le profil public de votre établissement. Seule l’entreprise retenue par l’utilisateur à le droit de le contacter. Vous pouvez ainsi rencontrer l’utilisateur en personne dès la visite technique.",
          button: "",
        },

        pricing: {
          title: "Bénéficiez de chantiers clés en main",
          paragraph:
            "Faites-vous payer automatiquement par virement bancaire en ligne. Le service est facturé que quand vous êtes payé : si vous gagnez 0 € pour un mois donné, notre service vous sera gratuit pour ce mois-ci. Les frais de service incluts la réalisation des démarches administratives, financières et assurancielles.",
          button: "",
        },

        "manage-the-projects-you-won": {
          title: "Concentrez-vous sur l'essenteil",
          paragraph:
            "Bénéficiez d’une plateforme intégrée avec signatures électroniques, paiements sécurisés, planification de chantiers et d’autres fonctionnalités d’automatisation. Nos pages de paiement prêtes à l’emploi s'intègrent dans vos méthodes de facturation, pour une expérience de paiement améliorée. Suivez ainsi les agendas, planifiez les visites, et pilotez le chantier à distance via notre tableau de bord simplifié.",
          button: "",
        },
      },
      pricing: {
        title: "Simple no-tricks pricing",
        paragraph:
          "Distinctio et nulla eum soluta et neque labore quibusdam. Saepe et quasi iusto modi velit ut non voluptas in. Explicabo id ut laborum.",
        "on-card": {
          title: "Lifetime membership",
          paragraph:
            "Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem assumenda.",
          "what-included": "What's included",
          items: {
            item1: "Private forum access1",
            item2: "Private forum access2",
            item3: "Private forum access3",
          },
          "pay-win": "Winning pricing",
          price: "$349",
          usd: "USD",
          button: "Get access",
          "little-line":
            "Invoices and receipts available for easy company reimbursement",
        },
      },
      cta: {
        title: "Join our network of installers today",
        paragraph: "Join our network to see more.",
        "button-1": "Démarrer maintenant",
        "button-2": "Documentation",
      },

      // Not in the page anymore //
      contact: {
        "contact-hero": {
          title: "Contactez-nous",
          paragraph:
            "Vel nunc non ut montes, viverra tempor. Proin lectus nibh phasellus morbi non morbi. In elementum urna ut volutpat. Sagittis et vel et fermentum amet consequat.",
        },
        "contact-form": {},
      },
      // Not in the page anymore //
    },

    administration: {
      hero: {
        administration: {
          title: "Administration",
          paragraph:
            "Simplifiez votre processus administratif avec projetsolaire.",
          button: "Get started",
          "request-a-demo": "Request a demo",
          "expert-at-service":"Experts at your service",
          "expert-at-service-desc":"Our team is here to listen to you and support you in your efforts.",
          "for-all-your-project-type":"For all your project types.",
          "stp":"Save time and permissions",
          "filling-information":"Filling in the information is left to us to do",
          "qas":"Quality and speed",
          "qas-desc":"Since 2020, our design office has built up unique know-how. Our expertise on thousands of files of all types allows us to overcome all issues. Our project managers are organized into teams and trained to meet all the obligations of the French administration. Our design office is led by electrical and construction engineers - who work side by side with our developers to design the innovative automations that have made our reputation.",
          "tools":"Our tools at your disposal",
          "tools-desc":"Take advantage of all the tools and services that our solution offers for your administration from start to finish.",
          "next-level":"Want to move to the next level?",
          "save-time":"Save time",
          "ryp":"Reduce your paperwork",
          "quality-service":"The quality of our service",
          "service-needs":"Service adapted to your needs",
          "sws":"They are satisfied with our service",
          "urban-planning":"The keys to understanding urban planning in your city",
          "urban-planning-desc":"With projetsolaire, be informed in a few seconds of the regulations of your city, without the need for additional research.",
          "learn-more-service":"Learn more about our services",
          "lms-desc":"With our wide range of tools, you will find what you are looking for thanks to our A to Z construction site management solution.",
          "need-clarification":"Need clarification?",
          "manage-service-title":"Easily manage your construction sites",
          "manage-service-desc":"Since 2020, our design office has built a deep expertise and recognized know-how. Accelerate your processes with our automations and your all-in-one construction site management platform.",
          tabs: {
            "on-the-roof":"On the roof",
            "on-the-ground":"On the ground",
            "on-a-carport":"On a carport",
            "on-a-pergola":"On a pergola",
            "on-a-roof-terrace":"On a roof terrace",
            "solar-tile":"Solar tile",
            "follow-up":"Follow up",
            "invite-your-customers":"Invite your customers",
            "request-for-completeness":"Request for completeness",
            "reminder":"Reminder",
            "problem-follow-up":"Problem follow-up",
            "residential-tab-title":"For residential construction sites",
            "business-tab-title":"For tertiary construction sites"
          },
          steps:{
              "step1": "Complete the information via mobile during the technical visit.",
              "step2": "We send your file directly to the town hall within 48 hours.",
              "step3": "We manage the processing of the file and follow up with the town halls.",
              "step4": "You receive your town hall approval, urban planning permit, and compliance."
          },

          "contact": "Contact",
          "appointment-desc": "Make an appointment with us to see together how we can simplify your organization and help you grow.",
          "get-in-touch": "Get in touch",
          "permission-title": "Save hours on every administrative file",
          "permission-desc": "Speed up your processes to reduce delays on your projects. Hundreds of installers already trust us.",
          "permission-title-2": "Eliminate weeks of delay on every administrative file",
          "permission-desc-2": "Speed up your processes to gain hours on your projects. Dozens of developers already trust us.",
          "management-title": "Collaborative, fast, and intuitive management",
          "management-desc": "Equip every member of your team with a powerful software for the execution and tracking of your projects.",
          "consular": {
            "residential": {
              "first": "SC 144 (A or B or C)",
              "second": "Electrical single-line diagram",
              "third": "Battery storage (Violet)",
              "title": "Visa requests for the Consuel Attestation",
              "description": "Carrying out all procedures with the Consuel for your photovoltaic system. Obtaining conformity certificates - blue or violet."
            },
            "business": {
              "first": "SC 144 (A or B or C)",
              "second": "Electrical single-line diagram",
              "third": "Attestation S21",
              "title": "Control office and Consuel visa",
              "description": "Carrying out all procedures with the Consuel for your photovoltaic system. Obtaining conformity certificates - blue or violet."
            }
          },
          "urbanPlanning": {
            residential: {
              first: "Prior Declarations",
              second: "Building Permit",
              third: "Friendly Appeal and DAACT",
              title:
                "Requests for Urban Planning Permissions",
              description:
                "The completion of the urban planning process for your photovoltaic system with local institutions, as well as ABF, CAUE, regional parks, etc.",
            },
            business: {
              first: "Prior Declarations 'Small Ground'",
              second: "Building Permit and Architect",
              third: "Bailiff's Report and DAACT",
              title:
                "Requests for Urban Planning Permissions",
              description:
                "The completion of the urban planning process for your photovoltaic system with local institutions, as well as ABF, CAUE, regional parks, etc.",
            },
          },
          "connections": {
            "residential": {
              "first": "Enedis or local utility",
              "second": "EDF Purchase Obligation or CACSI or ARPE",
              "third": "Virtual battery",
              "title": "Connection requests with EDF OA",
              "description": "Carrying out the connection procedures for your photovoltaic system with the distribution network operator and a co-contractor, up to 500 kVA."
            },
            "business": {
              "first": "Enedis or local utility",
              "second": "EDF Obligation to Purchase or ARPE",
              "third": "Collective self-consumption",
              "title": "Connection requests in LV or HTA",
              "description": "Carrying out the connection procedures for your photovoltaic system with the distribution network operator and a co-contractor, up to 500 kVA."
            }
          },
          "service-client-360": "360° Customer Service",
          "service-client-360-desc": "Keep control with our multi-channel customer service, available all year round.",
          "managementTabs": {
            "visite-technique": "Technical visit",
            "envois-48-heures": "48-hour sends",
            "suivi-daté-en-temps-reel": "Real-time dated tracking",
            "demande-de-pieces": "Request for documents",
            "relances": "Follow-ups",
            "visite-technique-title": "Mobile VT Assistance",
            "envois-48-heures-title": "Large-scale management",
            "suivi-daté-en-temps-reel-title": "Total transparency",
            "demande-de-pieces-title": "Simplified organization",
            "relances-title": "Accelerated processing",
            "visite-technique-desc": "Your technical sales representatives can easily upload photos and information on the go.",
            "envois-48-heures-desc": "Our pioneering automations and robots generate documents and fill out online forms.",
            "suivi-daté-en-temps-reel-desc": "Your secretariat can quickly start and track the progress of procedures on the same software.",
            "demande-de-pieces-desc": "Our design office processes requests for additional documents to unblock files.",
            "relances-desc": "Our project managers follow up with administrative bodies to reduce processing times."
          },
          "solutions-title": "On-demand and tailored solutions",
          "solutions-desc":
            "An automated, all-in-one management of all your photovoltaic operations by a single point of contact.",
          "solutions-orange": "No subscription. No commitment.",
          breadcrumb: {
           "administration": "Sizing",
           "autorisation-urbanisme": "Urban Planning Authorization",
           "demande-de-raccordement": "Connection Request",
           "demande-consuel": "Consul visa",
          },
          "procedure-title": "Administrative procedures",
          expertAtService: {
          "know-how-since":"Expertise since 2020",
          "know-how-since-desc":"A unique expertise and large-scale support.",
          "100-online":"100% online",
          "100-online-desc":"A fully online and mobile experience for maximum efficiency.",
          "speed-quality":"Speed and quality",
          "speed-quality-desc":"Thanks to an innovative and recognized automation process.",
          }
        },
      },
      "value-propositions": {
        "why-us": "Why us?",
        title: "All in one platform",
        "paragraph-1":
          "Dotez chaque membre de votre équipe d'un puissant logiciel pour la réalisation et le suivi de vos chantiers. L'outil informatique réduit vos délais, et permet à tous d’y voir plus clair.",
        "paragraph-2":
          "Vos commerciaux peuvent facilement télécharger les photos et documents sur votre compte.",
        "paragraph-3":
          "Votre secrétariat peut rapidement lancer puis suivre le déroulement des démarches sur le même logiciel.",
        "key-values": {
          value1: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value2: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value3: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value4: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value5: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value6: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value7: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
          value8: {
            title: "Wow cool stuff",
            description: "Explication here",
          },
        },
      },
      products: {
        title: "Our services",
        paragraph:
          "Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.",
        "requests-for-urban-planning-authorizations": {
          title: "Demandes d'autorisations d’urbanisme",
          subtitle:
            "La réalisation de la démarche d’urbanisme pour votre système photovoltaïque auprès de l’institution locale compétente.",
          "paragraph-1":
            "Dématérialisation des dossiers de déclaration préalable (ou permis de construire) grâce à l’API du Géoportail et l’utilisation systématique de guichet unique.",
          "paragraph-2":
            "Obtention de non-opposition, en PLU ou RNU. Envoi puis suivi des dossiers, avec preuves de dépôt et avis de réception des lettres recommandées. Relances matinales des mairies par téléphone.",
          "paragraph-3":
            "Interlocution avec Bâtiments de France (ABF), parcs régionaux, et autres autorités jusqu’à l’attestation de non-opposition à la conformité.",
        },
        "request-for-enedis": {
          title: "Demande de Raccordement",
          subtitle:
            "La réalisation de toutes les démarches de raccordement de votre système photovoltaïque auprès du réseau électrique Enedis et d'un cocontractant.",
          "paragraph-1":
            "Obtention de PDR ou PTF, CRAE ou CAE pour production d'électricité solaire (ou augmentation de puissance). Obtention aussi de la simulation du prix du devis Enedis selon le barème de facturation.",
          "paragraph-2":
            "Envoi et relance  Relance et suivi de l’avancement des dossiers par messagerie Enedis.",
          "paragraph-3":
            "Inclus aussi l’obtention EDF OA pour votre client, ou bien la démarche avec un co-contractant auprès de tous les fournisseurs de batterie virtuelle (Urban Solar Energy, MyLight Systems, JPME).",
        },
        "requests-for-consuel": {
          title: "Visa Consuel",
          subtitle:
            "La réalisation de toutes les démarches auprès du Consuel pour votre système photovoltaïque. Obtention des attestations de conformité.",
          "paragraph-1":
            "La réalisation de la fiche de collecte, du SC 144 (A/B) et du schéma électrique unifilaire auprès du Consuel pour votre système photovoltaïque. Gestion et suivi du dossier jusqu’à l’obtention pour mise en service de l’Attestation de Conformité « Bleu » (n°15523*01). Création et Test de l’attestation. Prévention de l’inspection du Consuel et planification de la visite.",
          "paragraph-2":
            "Avertissement : service exclut l’achat des d’Attestations de Conformité (AC). Le service numérique n’est à ce jour pas connecté au compte Consuelpro.com",
        },
      },
      "new-pricing": {
        pricing: "Pricing",
        title: "Pricing plans for teams of all sizes",
        paragraph:
          "Choose an affordable plan that’s packed with the best features for engaging your audience, creating customer loyalty, and driving sales.",
        "producer-size": "Producer size",
        option: {
          "small-producer": "Small producer",
          "large-producer": "Large producer",
        },
        "buy-plan": "Buy plan",

        "price-card": {
          "requests-for-urban": {
            title: "Demandes d'autorisations d'Urbanisme",
            price: {
              small: "149€HT",
              big: "249€HT",
            },
            features: {
              feat1: "feature1",
              feat2: "feature2",
              feat3: "feature3",
              feat4: "feature4",
            },
            description:
              "La réalisation de la démarche d’urbanisme pour votre système photovoltaïque auprès de l’institution locale compétente. Dématérialisation des demandes grâce à l’API du Géoportail et l’utilisation systématique de guichet unique. Obtention de non-opposition de déclaration préalable ou permis de construire, en PLU ou RNU. Envoi puis suivi des dossiers, avec preuves de dépôt et avis de réception des lettres recommandées. Interlocution avec Bâtiments de France (ABF), parcs régionaux, et autres autorités jusqu’à l’attestation de non-opposition à la conformité.",
          },
          "requests-for-enedis": {
            title: "Demandes de raccordement",
            price: {
              small: "99€HT",
              big: "699€HT",
            },
            features: {
              feat1: "feature1",
              feat2: "feature2",
              feat3: "feature3",
              feat4: "feature4",
            },
            description:
              "La réalisation de toutes les démarches de raccordement de votre système photovoltaïque auprès du réseau électrique public, Enedis. Obtention de PDR ou PTF, CRAE ou CAE. Obtention de la simulation du prix du devis Enedis selon le barème de facturation. Relance et suivi de l’avancement des dossiers par messagerie Enedis. Inclus aussi l’obtention EDF OA pour votre client, ou bien la démarche avec un co-contractant auprès de tous les fournisseurs de batterie virtuelle (Urban Solar Energy, MyLight Systems, JPME)..",
          },
          "requests-for-consuel": {
            title: "Demandes de Visa Consuel",
            price: {
              small: "49€HT",
              big: "99€HT",
            },
            features: {
              feat1: "feature1",
              feat2: "feature2",
              feat3: "feature3",
              feat4: "feature4",
            },
            description:
              "La réalisation de la fiche de collecte, du SC-144 (A/B/C) et du schéma électrique unifilaire auprès du Consuel pour votre système photovoltaïque. Gestion et suivi du dossier jusqu’à l’obtention pour mise en service de l’Attestation de Conformité « Bleu » (n°15523*01). Création et Test de l’attestation. Prévention de l’inspection du Consuel et planification de la visite.",
          },
          package: {
            title: "Package",
            price: {
              small: "249€HT",
              big: "999€HT",
            },
            features: {
              feat1: "Unlimited products",
              feat2: "Unlimited subscribers",
              feat3: "Advanced analytics",
            },
            description:
              "La réalisation de l’ensemble des démarches administratives pour particuliers – d’autorisations d’urbanisme, Enedis, Visa Consuel, EDF OA ou batterie virtuelle, jusqu’à la mise en service.",
          },
        },
      },
      cta: {
        title: "Externalise your administration today.",
        paragraph:
          "Ac euismod vel sit maecenas id pellentesque eu sed consectetur. Malesuada adipiscing sagittis vel nulla.",
        button: "Get started",
        "learn-more": "Learn more",
      },
    },
    "digital-sales-tools": {
      hero: {
        interested: "Interested ?",
        "request-a-demo": "Request a demo",
        title: "A better way to present to your client",
        paragraph:
          "Esse id magna consectetur fugiat non dolor in ad laboris magna laborum ea consequat. Nisi irure aliquip nisi adipisicing veniam voluptate id. In veniam incididunt ex veniam adipisicing sit.",
        button: "Get started",
        "learn-more": "Learn more",
        animation: {
          intro: {
            welcome: "Bienvenue",
          },
          production: {
            title: "Annual production",
            paragraph:
              "Votre potentielle production d'électricité solaire est déterminée en fonction du nombre et de la puissance (en kWc) de vos panneaux solaires,ainsi que de leurs rendements énergétiques (en kWh/kWc).",
          },
          consumption: {
            title: "Consommation annuel",
            paragraph:
              "Votre consommation annuelle d'électricité est déterminée en fonction de la quantité d'électricité que vous utilisez, mesurée en kilowattheures (kWh).Cela dépend de vos habitudes de consommation.",
          },
          system: {
            title: "Mon système solaire",
            paragraph:
              "Nous vous réalisons votre projet solaire. Voici votre bien actuel sans panneaux photovoltaïques.",
            value: {
              "technical-specs": {
                title: "Cahier de charges",
                value: "16 kWc",
              },
              type: {
                title: "Type",
                value: "Classique",
              },
              "cellular-photovoltaique": {
                title: "Cahier de charges",
                value: "Monocrystallins",
              },
              inverters: {
                title: "Onduleur",
                value: "Micro-ondeleurs",
              },
              "manufacturer-warranty": {
                title: "Guarantie de fabricant",
                value: "25 ans",
              },
            },
          },
        },
      },
      configurable: {
        styling: {
          title: "Pick you companies branding",
          paragraph:
            "Want to personalise your clients presentations to your brands colour scheme? No problem!",
        },
      },

      "value-proposition": {
        "everything-you-need": "Everything you need",
        title: "No server? No problem.",
        paragraph:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis.",
        "key-value": {
          a: {
            title: "lidar",
            description: "bla bla bla",
          },
          b: {
            title: "hourly",
            description: "bla bla bla...",
          },
          c: {
            title: "your bill",
            description: "Bla bla bla !",
          },
        },
      },
      features: {
        a: {
          title: "Stay on top of customer support",
          paragraph:
            "Accédez à la courbe de charge réelle du propriétaire. Notre étude technico-financière sur 25 ans repose sur les mesures horaires (en kWh). L’historique de consommation est obtenu directement depuis le compteur Linky d'Enedis. Cette précision technique permet de calculer l'autoconsommation électrique heure par heure.",
          paragraph2: "projetsolaire :",
          paragraph3: "une entité référencée auprès d'Enedis",
          button: "Get started",
        },
        b: {
          title: "Better understand your customers",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
        c: {
          title: "Stay on top of customer support",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
        d: {
          title: "Stay on top of customer support",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
        e: {
          title: "Better understand your customers",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
        f: {
          title: "Stay on top of customer support",
          paragraph:
            "Semper curabitur ullamcorper posuere nunc sed. Ornare iaculis bibendum malesuada faucibus lacinia porttitor. Pulvinar laoreet sagittis viverra duis. In venenatis sem arcu pretium pharetra at. Lectus viverra dui tellus ornare pharetra.",
          button: "Get started",
        },
      },
      pricing: {
        title: "Simple pay as you go pricing",
        paragraph:
          "If you're not satisfied, contact us within the first 14 days and we'll send you a full refund.",
        "on-card": {
          title: "Presentation",
          paragraph:
            "Lorem ipsum dolor sit amet consect etur adipisicing elit. Itaque amet indis perferendis blanditiis repellendus etur quidem assumenda.",
          "what-included": "What's included",
          features: {
            feat1: "Digital web link to share with client",
            feat2: "Up to 3x changes can be requested",
            feat3: "Team member access",
            feat4: "Customisable slides",
          },
          "pay-as-you-go": "Pay as you go",
          "price-big": "100 €",
          "price-small": "Starting at",
          "join-waiting-list": "Learn about our membership policy",
          button: "Get started",
          "free-sample": "Get a free sample",
        },
      },
      "help-center": {
        title: "Need help getting started ?",
        paragraph:
          "Incididunt sint fugiat pariatur cupidatat consectetur sit cillum anim id veniam aliqua proident excepteur commodo do ea.",
        button: "Read our docs",
        "request-a-demo": "Request a demo",
      },
    },

    discover: {
      systeme: {
        datasheet: {
          title: "Fiche technique",
          paragraph:
            "Vous trouverez ci-dessous les détails techniques du projet",
          systemepower: "Puissance du système",
          numberpanels: "Nombre de panneau",
          panelstype: "Type de panneaux",
          roofheight: "Hauteur du toit",
          roofstructure: "Structure du toit",
          roofmaterials: "Matériaux du toit",
          roofaccessibility: "Accessibilité du toit",
          panelpreference: "Préférence panneau",
          quotenumber: "Nombre de devis reçu",
          Typenetworkconnection: "Type de branchement",
          powernetworkkva: "Puissance de la Souscription du réseau en kVa",
        },
      },
    },

    "case-studies": {
      home: {
        header: {
          title: "Our customers stories",
          overview: "Overview",
          all: "All customer stories",
        },
        overview: {
          hero: {
            title:
              "La plateforme pour photovoltaïque qui soutient des entreprises locales partout en France",
            paragraph:
              "L'objectif de notre plateforme est de permettre aux installateurs photovoltaïques à forte croissance d'exploiter les technologies numériques de pointe de manière simple et collaborative. Notre solution informatique est co-développée avec nos clients pour qu'ils puissent gagner du temps pour faire plus. Nous opérons sur l'ensemble du territoire national et propulsons des milliers de projets solaires chaque année pour des entreprises de toutes tailles, des artisans de proximité aux leaders du solaire.",
          },

          testimonials: {
            heading: "Testimonials",
            title: "We have worked with hundreds of amazing installers",
          },

          cta: {
            title: "Envie d’essayer ?",
          },
        },
        all: {
          title:
            "L’outil informatique pour la filière photovoltaïque de France",
        },
      },
      entry: {
        breadcrumb: {
          installer: "Installers",
          "case-studies": "Case studies",
        },
        "case-study": "Case study",
        "learn-more":
          "Learn more about how we can help your installation business",
      },
    },
    "use-cases": {
      heading:{
       "find-new-client":"Find New Clients",
       "solar-sizing":"Solar Sizing",
       "sales-and-marketing":"Sales and Marketing",
       "quotes-and-billing":"Quotes and Billing",
       "stocks-and-equipment":"Stocks and Equipment",
       "administrative-procedures":"Administrative Procedures",
       "file-tracking":"File Tracking",
       "purchase-contracts-and-business-models":"Purchase Contracts and Business Models",
       "financing":"Financing",
       "work-and-construction":"Work and Construction",
       "web-and-mobile-software-for-project-management":"Web and Mobile Software for Project Management",
       "post-installation-services":"Post-Installation Services",
       "new-prospects-and-marketing":"New Prospects and Marketing",
       "customer-service":"Customer Service",
       "premium":"Premium"
      },
      action:{
        "price-based-on-your-€-wc":"Price based on your €/Wc",
        "no-subscription-required":"No subscription required",
        "no-subscription":"No subscription",
        "free":"Free",
        "usage-based-pricing":"Usage-based pricing",
      },
      "request-demo":"Request a demo",
      "start-now":"Start now",
      "testimonials": {
        "header": "Customer Testimonials",
        "title": "Who better than our customers to talk about projetsolaire.com"
      },
      "cta": {
        "title": "Outperform the competition",
        "description": "Discover why smart teams are already using projetsolaire.com"
      },
      "project-contact":{
        "title": "All your projects. A single specialized contact.",
        "description": "Since 2020, our design office has built up in-depth expertise and recognized know-how. Speed ​​up your construction sites and files with our automations and your all-in-one construction site management platform.",
        "button": "Send",
        "services":{
        service1:{
          "title": "All powers in KW and MW",
          "description": "Our team of specialists accompanies you for particular, tertiary, BT and HTA projects.",
        },
        service2:{
          "title": "All types of PV construction sites",
          "description": "Solar projects on roofs, new builds, shade structures, ‘small ground’? We can help you.",
        },
        service3:{
          "title": "Everywhere in France",
          "description": "In DROM-COM? We are present in Metropolitan France and Overseas.",
        }
      }
      },
    SMALL: {
      hero: {
        "icon-label": "Case Studies",
        "discover-solutions": "Discover Solutions",
      "book-demo": "Book a Demo",
       "name-orange": "Solar artisan",
          "name-black": "looking to save time",
          "description": "Are you a local photovoltaic installer? Discover how professionals use projetsolaire daily to make money."
      },
      works: {
        "heading": "How it works",
          "title": "Digital study office serving the photovoltaic craft industry.",
          "steps": {
            "step1": {
              "title": "Account creation",
              "heading": "No commitment. Quick and free online registration. Then, complete the account with decennial insurance, RGE, and other documents."
            },
            "step2": {
              "title": "Add the first project",
              "heading": "Enter a client and a project in just a few clicks. Manage dates and travel, then the material list and quotes using the tool."
            },
            "step3": {
              "title": "Add services a la carte",
              "heading": "Need to do the layout? A full study with a quote? Or the municipal agreement? Use only what you need."
            }
      },
      "product-listing": {
        "networking-title": "Networking",
        "networking-desc":
          "Discover potential clients directly from your account, without paying commissions to commercial intermediaries.",
        "project-management-assistance-title": "Project Management Assistance",
        "project-management-assistance-desc":
          "The project manager sets the price and materials. Benefit from the technical visit and electronic signature tools to accelerate your sales.",
        "3d-sizing-tool-title": "3D Sizing Tool",
        "3d-sizing-tool-desc":
          "We have integrated Aurora Solar to provide you with the most powerful and technically accurate photovoltaic solution.",
        "shading-masks-title": "Shading Masks Compliant with RGE Audit",
        "shading-masks-desc":
          "Opt for the precision of 0.10m LIDAR in 3D to perform sunlight assessments in kWh/m² throughout France.",
        "enedis-api-title": "Enedis API and Linky Meter Data",
        "enedis-api-desc":
          "We have integrated Aurora Solar to offer you the most powerful and technically accurate photovoltaic production solution.",
        "self-consumption-title": "Self-consumption or Full-sale Studies",
        "self-consumption-desc":
          "Offer extraordinary and complete studies, with an unlimited number of techno-financial proposals per study.",
        "virtual-battery-title": "Virtual Battery Comparison Studies",
        "virtual-battery-desc":
          "Compare the profitability of the EDF OA contract with virtual battery providers MyLight Systems, Urban Solar Energy, JPME.",
        "physical-battery-title": "Physical Battery Studies",
        "physical-battery-desc":
          "Add a physical battery model and technical sheet for hourly analysis of self-consumption and storage profitability.",
        "profitability-studies-title": "Techno-financial Profitability Studies",
        "profitability-studies-desc":
          "Define the business plan for the budget. Calculate financial forecasts over 25 years. Analyze the cash flow table for the investment over the life of the project.",
        "edf-oa-tariff-title":
          "Watch the EDF OA Purchase Tariff in Mainland France and ZNI",
        "edf-oa-tariff-desc":
          "The state premiums and purchase tariffs of our studies are updated every quarter with the new tariff decrees from the CRE.",
        "tariff-comparison-title": "Comparison of Purchase Tariffs by T-0",
        "tariff-comparison-desc":
          "Compare the profitability of photovoltaic plants with state aid across different quarters to speed up your sales.",
        "credit-simulator-title": "Credit and Financing Simulator",
        "credit-simulator-desc":
          "Analyze the investment in cash, through a loan, or through roof leasing - directly integrated into the study.",
        "support-formats-title": "All Support Formats in White Label",
        "support-formats-desc":
          "Studies in interactive mini-site, PDF, spreadsheet, or mobile format.",
        "quotes-materials-title": "Quotes with Integrated Materials",
        "quotes-materials-desc":
          "Prepare your quotes from a database with thousands of modules, inverters, enclosures, batteries.",
        "accounting-numbering-title": "Accounting Numbering of Quotes",
        "accounting-numbering-desc":
          "Also customize your colors, your general terms and conditions, technical sheets, VAT certificate, and much more for your quotes.",
        "electronic-signature-title": "Legally Compliant Electronic Signatures",
        "electronic-signature-desc":
          "Write and have your quotes signed online, with your references and mentions. Access your quotes and documents in your account.",
        "invoices-deposits-title":
          "Accounting Invoices for Deposits and Balances",
        "invoices-deposits-desc":
          "Define your deposits, manage your transactions, add your invoices, and much more.",
        "secure-payments-title":
          "Secure Payments by Bank Transfer or Credit Card",
        "secure-payments-desc":
          "Receive your payments via simple payment pages, with several payment methods, integrated into your quotes and invoices.",
        "product-references-title":
          "10,000+ Product References: Modules, Inverters, Cabinets, Batteries ...",
        "product-references-desc":
          "Choose the product model from our real-time updated database. Save your kits and equipment lists for reduced quote and procedure input.",
        "material-lists-title": "Project Material Lists",
        "material-lists-desc":
          "Automatic planning of product and equipment lists for photovoltaic installations.",
        "technical-documents-title":
          "Technical Datasheets, Compliance Certificates, and Carbon Footprint Report",
        "technical-documents-desc":
          "Our database contains thousands of up-to-date documents for your procedures.",
        "technical-docs-title": "Professional Technical Documentation",
        "technical-docs-desc":
          "Automatically generate wiring diagrams, strapping certificates, and execution plans.",
        "material-ordering-title": "Material Ordering",
        "material-ordering-desc":
          "Request quotes from manufacturers and distributors to find competitive prices online.",
        "urban-planning-title": "Urban Planning Authorization Requests",
        "urban-planning-desc":
          "Filing the Preliminary Declaration or Building Permit for your photovoltaic installation with the relevant local authorities for approval at the town hall.",
        "gracious-appeal-title": "Gracious Appeal and DAACT",
        "gracious-appeal-desc":
          "Filing the DAACT for your photovoltaic installation with the relevant local authorities. In case of refusal, filing the gracious appeal.",
        "architect-communication-title": "Communication with Architects",
        "architect-communication-desc":
          "Conducting PLU/RNU feasibility studies, preparing graphic documents, architect certification, and submission at the town hall as required by architect regulations.",
        "connection-requests-title": "Connection Requests",
        "connection-requests-desc":
          "Obtaining the CAE contract and commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "collective-connection-title":
          "Collective Self-Consumption Connection Requests",
        "collective-connection-desc":
          "Our specialized partner is directly integrated into your account for community creation and PMO. Conducting CAE requests and distribution keys.",
        "consuel-certificate-title": "Consuel Certificate Visa Requests",
        "consuel-certificate-desc":
          "Filing the SC144-A, SC144-B, or SC144-C, preparing the one-line electrical diagram, then submitting the Blue or Violet Conformity Certificate (for batteries).",
        "control-office-title": "Control Office and Consuel Visa",
        "control-office-desc":
          "Technical communication with accredited organizations and obtaining the DRE conformity report. Preparing the SC144, one-line electrical diagram, and submitting the Blue or Violet AC.",
        "large-scale-sending-title": "Sending Within 48 Hours on a Large Scale",
        "large-scale-sending-desc":
          "Our pioneering automation and robots generate documents and fill out online forms.",
        "real-time-tracking-title": "Real-time Dated Tracking",
        "real-time-tracking-desc":
          "Your secretariat can quickly initiate and then track the progress of procedures on the same software.",
        "additional-docs-title": "Processing Requests for Additional Documents",
        "additional-docs-desc":
          "Our study office processes requests for additional documents to unblock the files.",
        "follow-ups-title": "Follow-ups with Administrative Bodies",
        "follow-ups-desc":
          "Our project managers follow up with administrative bodies to reduce processing times.",
        "edf-obligation-title": "EDF Purchase Obligation",
        "edf-obligation-desc":
          "Carrying out the procedure with EDF OA, automatic Affidavit or the S21 'ENR' Certificate with Carbon Footprint (for systems over 100 kWc).",
        "virtual-battery-procedure-title": "Virtual Battery Approach",
        "virtual-battery-procedure-desc":
          "Carrying out the procedure with one of the three virtual battery providers - MyLight Systems, Urban Solar Energy, JPME - for virtual storage.",
        "financing-report-title": "Financing Profitability Report",
        "financing-report-desc":
          "Analyze the financing of the photovoltaic investment based on energy yield, from an hourly kWh calculation.",
        "payment-savings-title":
          "Monthly Payment and Savings Effort Calculation",
        "payment-savings-desc":
          "Compare the financing of the photovoltaic investment, whether in cash or through a bank loan, integrating your OPEX and EBITDA.",
        "solar-subscription-title": "Solar Subscription",
        "solar-subscription-desc":
          "Our partner specializing in subscriptions is directly integrated into your account. Make solar subscription financing requests from the study stage.",
        "mobile-assistance-title": "Mobile Assistance for Technical Visits",
        "mobile-assistance-desc":
          "Your technical sales representatives can easily fill in client forms and upload photos and information while on the move.",
        "material-planning-title": "Material Planning",
        "material-planning-desc":
          "Automatic inventory lists of equipment available per site and per flow, for controlling material and orders.",
        "integrated-calendar-title": "Integrated Calendar",
        "integrated-calendar-desc":
          "Each team member has access, from their own account, to the company calendar for planning travels and installations. Add Google Calendar or Microsoft Outlook.",
        "client-relations-title": "Client Relations and CRM",
        "client-relations-desc":
          "Client assignments, comment space on client forms, file storage. Customizable automatic email notifications.",
        "team-access-title": "Team Access and Roles",
        "team-access-desc":
          "Sales, secretarial, and technical teams can each customize their interface. Managers have access to payments. Accounting has email access to invoices.",
        "integration-business-referrers-title":
          "Integration with Business Referrers",
        "integration-business-referrers-desc":
          "Business referrers only have access to their clients. Information visibility is customizable.",
        "custom-dashboard-title": "Customizable Dashboard",
        "custom-dashboard-desc":
          "Each team member can adjust their dashboard. Manage your sizing, studies, administrative procedures, and construction in one place.",
        "sales-cycle-tracking-title": "Sales Cycle Tracking and Reports",
        "sales-cycle-tracking-desc":
          "View key sales figures and activity in real-time. Our platform generates statistics of your projects. Receive automatic reports by email.",
        "data-export-title": "Data Export",
        "data-export-desc":
          "Need to analyze data in Excel? Need to secure the data? Export all tables and files in CSV format.",
        "as-built-files-title": "Automatic As-Built Files (DOE)",
        "as-built-files-desc":
          "Generate DOE reports in PDF or web format, shareable with clients and third parties for work acceptance reports and insurance audits.",
        "maintenance-contracts-title": "Maintenance Contracts",
        "maintenance-contracts-desc":
          "Help your clients schedule and pay for after-sales services (SAV) and maintenance contracts through online payment pages.",
        "white-label-title": "100% White Label",
        "white-label-desc":
          "Add your logo and main colors, buttons, and graphics. Customize the interfaces. Enhance your brand.",
        "white-label-client-portal-title": "White Label Client Portal",
        "white-label-client-portal-desc":
          "Invite your clients to an online space to view your study, sign your quote, and track the progress of their case. Your clients can enter information and documents.",
        "login-registration-pages-title":
          "Login and Registration Pages on Website",
        "login-registration-pages-desc":
          "Invite your clients to log into their white label client portal from your website.",
        "seo-title": "Search Engine Optimization and SEO",
        "seo-desc":
          "Fill in the customizable profile 'Our establishment' available on studies and in our directory.",
        "online-messaging-title": "Online Messaging",
        "online-messaging-desc":
          "Our team responds within minutes with written records.",
        "phone-support-title": "Phone Support",
        "phone-support-desc":
          "Our team is always available to assist you with your procedures.",
        "email-title": "Email",
        "email-desc":
          "Our team is available by email to facilitate your communications with third parties.",
        "video-conference-appointments-title": "Video Conference Appointments",
        "video-conference-appointments-desc":
          "Our team is here to listen and help you develop your follow-up together.",
        "file-transfer-title": "File Transfer",
        "file-transfer-desc":
          "Our IT teams assist you in migrating data and files to our system so you can manage operations.",
      },
      }
    },
    REGIONAL: {
      hero: {
        "icon-label": "Case Studies",
        "discover-solutions": "Discover Solutions",
      "book-demo": "Book a Demo",
       "name-orange": "Local SME",
          "name-black": "enhancing its brand image",
          "description": "Are you a photovoltaic installer operating in multiple regions? Discover how professionals use projetsolaire to simplify their daily operations."
      },
      works: {
        heading: "How it works",
        title:
          "Digital study office designed for photovoltaic teams.",

        steps: {
          step1: {
            title: "Account creation",
            heading:
              "No commitment. Quick and free online registration. Then, complete the account with decennial insurance, RGE, and other documents.",
          },
          step2: {
            title: "Invite the team",
            heading:
              "Enter your teammates in just a few clicks. Manage roles and access for your teams. Oversee clients, projects, and travel.",
          },
          step3: {
            title: "Starting training",
            heading:
              "Your teams are trained by our team on the IT solutions. Our teams will assist you with IT integrations.",
          },
        },
      },
      "product-listing": {
        "networking-title": "Networking",
        "networking-desc":
          "Discover potential clients directly from your account, without paying commissions to business introducers.",
        "project-management-assistance-title": "Project Management Assistance",
        "project-management-assistance-desc":
          "The project manager sets the price and materials. Benefit from the technical visit and electronic signature tools to accelerate your sales.",
        "3d-sizing-tool-title": "3D Sizing Tool",
        "3d-sizing-tool-desc":
          "We have integrated Aurora Solar to provide you with the most powerful and technically accurate photovoltaic yield solution.",
        "shading-masks-title": "Shading Masks Compliant with RGE Audit",
        "shading-masks-desc":
          "Opt for the precision of 0.10m LIDAR in 3D to perform sunlight assessments in kWh/m² throughout France.",
        "enedis-api-title": "Enedis API and Linky Meter Data",
        "enedis-api-desc":
          "We have integrated Aurora Solar to offer you the most powerful and technically accurate photovoltaic production solution.",
        "self-consumption-studies-title":
          "Self-consumption or full-sale studies",
        "self-consumption-studies-desc":
          "Offer extraordinary and complete studies, with an unlimited number of techno-financial proposals per study.",
        "virtual-battery-comparison-title":
          "Virtual Battery Comparison Studies",
        "virtual-battery-comparison-desc":
          "Compare the profitability of the EDF OA contract with virtual battery providers MyLight Systems, Urban Solar Energy, JPME.",
        "physical-battery-studies-title": "Physical Battery Studies",
        "physical-battery-studies-desc":
          "Add a physical battery model and technical sheet for hourly analysis of self-consumption and storage profitability.",
        "techno-financial-profitability-studies-title":
          "Techno-financial Profitability Studies",
        "techno-financial-profitability-studies-desc":
          "Define the business plan for the budget. Calculate financial forecasts over 25 years. Analyze the cash flow table for the investment over the life of the project.",
        "edf-oa-purchase-tariff-title":
          "Watch the EDF OA purchase tariff in mainland France and ZNI",
        "edf-oa-purchase-tariff-desc":
          "The state premiums and purchase tariffs of our studies are updated every quarter with the new tariff decrees from the CRE.",
        "purchase-tariff-comparison-title":
          "Comparison of purchase tariffs by T-0",
        "purchase-tariff-comparison-desc":
          "Compare the profitability of photovoltaic plants with state aid across different quarters to speed up your sales.",
        "credit-financing-simulator-title": "Credit and Financing Simulator",
        "credit-financing-simulator-desc":
          "Analyze the investment in cash, through a loan, or through roof leasing - directly integrated into the study.",
        "enedis-costs-simulator-title": "Enedis Costs Simulator",
        "enedis-costs-simulator-desc":
          "Estimate the costs of reinforcing the electrical network based on Enedis' billing scale. The only alternative to the PTF in France.",
        "white-label-support-formats-title":
          "All support formats in white label",
        "white-label-support-formats-desc":
          "Studies in interactive mini-site, PDF, spreadsheet, or mobile format.",
        "quotes-with-integrated-materials-title":
          "Quotes with integrated materials",
        "quotes-with-integrated-materials-desc":
          "Prepare your quotes from a database with thousands of modules, inverters, enclosures, batteries.",
        "accounting-numbering-of-quotes-title":
          "Accounting numbering of quotes",
        "accounting-numbering-of-quotes-desc":
          "Also customize your colors, your general terms and conditions, technical sheets, VAT certificate, and much more for your quotes.",
        "legally-compliant-electronic-signatures-title":
          "Legally compliant electronic signatures",
        "legally-compliant-electronic-signatures-desc":
          "Write and have your quotes signed online, with your references and mentions. Access your quotes and documents in your account.",
        "accounting-invoices-for-deposits-and-balances-title":
          "Accounting invoices for deposits and balances",
        "accounting-invoices-for-deposits-and-balances-desc":
          "Define your deposits, manage your transactions, add your invoices, and much more.",
        "secure-payments-by-bank-transfer-or-credit-card-title":
          "Secure payments by bank transfer or credit card",
        "secure-payments-by-bank-transfer-or-credit-card-desc":
          "Receive your payments via simple payment pages, with several payment methods, integrated into your quotes and invoices.",
        "10000-plus-product-references-title":
          "10,000+ product references: modules, inverters, cabinets, batteries ...",
        "10000-plus-product-references-desc":
          "Choose the product model from our real-time updated database. Save your kits and equipment lists for reduced quote and procedure input.",
        "project-material-lists-title": "Project material lists",
        "project-material-lists-desc":
          "Automatic planning of product and equipment lists for photovoltaic installations.",
        "technical-datasheets-compliance-certificates-and-carbon-footprint-report-title":
          "Technical datasheets, Compliance Certificates, and Carbon Footprint Report",
        "technical-datasheets-compliance-certificates-and-carbon-footprint-report-desc":
          "Our database contains thousands of up-to-date documents for your procedures.",
        "professional-technical-documentation-title":
          "Professional technical documentation",
        "professional-technical-documentation-desc":
          "Automatically generate wiring diagrams, Strapping certificates, and Execution plans.",
        "material-ordering-title": "Material ordering",
        "material-ordering-desc":
          "Request quotes from manufacturers and distributors to find competitive prices online.",
        "urban-planning-authorization-requests-title":
          "Urban Planning Authorization Requests",
        "urban-planning-authorization-requests-desc":
          "Filing the Preliminary Declaration or Building Permit for your photovoltaic installation with the relevant local authorities for approval at the town hall.",
        "gracious-appeal-and-daact-title": "Gracious Appeal and DAACT",
        "gracious-appeal-and-daact-desc":
          "Filing the DAACT for your photovoltaic installation with the relevant local authorities. In case of refusal, filing the gracious appeal.",
        "communication-with-architects-title": "Communication with Architects",
        "communication-with-architects-desc":
          "Conducting PLU/RNU feasibility studies, preparing graphic documents, architect certification, and submission at the town hall as required by architect regulations.",
        "connection-requests-title": "Connection Requests",
        "connection-requests-desc":
          "Obtaining the CAE contract and commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "low-or-medium-voltage-connection-requests-title":
          "Low or Medium Voltage Connection Requests",
        "low-or-medium-voltage-connection-requests-desc":
          "Obtaining the PTF and CACS-i contract, then commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "collective-self-consumption-connection-requests-title":
          "Collective Self-Consumption Connection Requests",
        "collective-self-consumption-connection-requests-desc":
          "Our specialized partner is directly integrated into your account for community creation and PMO. Conducting CAE requests and distribution keys.",
        "consuel-certificate-visa-requests-title":
          "Consuel Certificate Visa Requests",
        "consuel-certificate-visa-requests-desc":
          "Filing the SC144-A, SC144-B, or SC144-C, preparing the one-line electrical diagram, then submitting the Blue or Violet Conformity Certificate (for batteries).",
        "control-office-and-consuel-visa-title":
          "Control Office and Consuel Visa",
        "control-office-and-consuel-visa-desc":
          "Technical communication with accredited organizations and obtaining the DRE conformity report. Preparing the SC144, one-line electrical diagram, and submitting the Blue or Violet AC.",
        "large-scale-sending-title": "Sending Within 48 Hours on a Large Scale",
        "large-scale-sending-desc":
          "Our pioneering automation and robots generate documents and fill out online forms.",
        "real-time-tracking-title": "Real-time Dated Tracking",
        "real-time-tracking-desc":
          "Your secretariat can quickly initiate and then track the progress of procedures on the same software.",
        "additional-docs-title": "Processing Requests for Additional Documents",
        "additional-docs-desc":
          "Our study office processes requests for additional documents to unblock the files.",
        "follow-ups-title": "Follow-ups with Administrative Bodies",
        "follow-ups-desc":
          "Our project managers follow up with administrative bodies to reduce processing times.",
        "edf-obligation-title": "EDF Purchase Obligation",
        "edf-obligation-desc":
          "Carrying out the procedure with EDF OA, automatic Affidavit or the S21 'ENR' Certificate with Carbon Footprint (for systems over 100 kWc).",
        "virtual-battery-procedure-title": "Virtual Battery Approach",
        "virtual-battery-procedure-desc":
          "Carrying out the procedure with one of the three virtual battery providers - MyLight Systems, Urban Solar Energy, JPME - for virtual storage.",
        "financing-report-title": "Financing Profitability Report",
        "financing-report-desc":
          "Analyze the financing of the photovoltaic investment based on energy yield, from an hourly kWh calculation.",
        "payment-savings-title":
          "Monthly Payment and Savings Effort Calculation",
        "payment-savings-desc":
          "Compare the financing of the photovoltaic investment, whether in cash or through a bank loan, integrating your OPEX and EBITDA.",
        "solar-subscription-title": "Solar Subscription",
        "solar-subscription-desc":
          "Our partner specializing in subscriptions is directly integrated into your account. Make solar subscription financing requests from the study stage.",
        "creation-and-management-of-spvs-title":
          "Creation and Management of SPVs",
        "creation-and-management-of-spvs-desc":
          "Your team organizes project portfolios by SPV directly from your account. Our project managers can create the SIRET numbers and assist with notaries.",
        "mobile-assistance-for-technical-visits-title":
          "Mobile Assistance for Technical Visits",
        "mobile-assistance-for-technical-visits-desc":
          "Your technical sales representatives can easily fill in client forms and upload photos and information while on the move.",
        "material-planning-title": "Material Planning",
        "material-planning-desc":
          "Automatic inventory lists of equipment available per site and per flow, for controlling material and orders.",
        "integrated-calendar-title": "Integrated Calendar",
        "integrated-calendar-desc":
          "Each team member has access, from their own account, to the company calendar for planning travels and installations. Add Google Calendar or Microsoft Outlook.",
        "subcontractor-management-for-installers-title":
          "Subcontractor Management for Installers",
        "subcontractor-management-for-installers-desc":
          "Your technical documents can be shared directly from your account with your project partners, to facilitate installation, Consuel, and Control Bureau.",
        "client-relations-title": "Client Relations and CRM",
        "client-relations-desc":
          "Client assignments, comment space on client forms, file storage. Customizable automatic email notifications.",
        "team-access-title": "Team Access and Roles",
        "team-access-desc":
          "Sales, secretarial, and technical teams can each customize their interface. Managers have access to payments. Accounting has email access to invoices.",
        "integration-business-referrers-title":
          "Integration with Business Referrers",
        "integration-business-referrers-desc":
          "Business referrers only have access to their clients. Information visibility is customizable.",
        "custom-dashboard-title": "Customizable Dashboard",
        "custom-dashboard-desc":
          "Each team member can adjust their dashboard. Manage your sizing, studies, administrative procedures, and construction in one place.",
        "sales-cycle-tracking-title": "Sales Cycle Tracking and Reports",
        "sales-cycle-tracking-desc":
          "View key sales figures and activity in real-time. Our platform generates statistics of your projects. Receive automatic reports by email.",
        "data-export-title": "Data Export",
        "data-export-desc":
          "Need to analyze data in Excel? Need to secure the data? Export all tables and files in CSV format.",
        "as-built-files-title": "Automatic As-Built Files (DOE)",
        "as-built-files-desc":
          "Generate DOE reports in PDF or web format, shareable with clients and third parties for work acceptance reports and insurance audits.",
        "maintenance-contracts-title": "Maintenance Contracts",
        "maintenance-contracts-desc":
          "Help your clients schedule and pay for after-sales services (SAV) and maintenance contracts through online payment pages.",
        "white-label-title": "100% White Label",
        "white-label-desc":
          "Add your logo and main colors, buttons, and graphics. Customize the interfaces. Enhance your brand.",
        "white-label-client-portal-title": "White Label Client Portal",
        "white-label-client-portal-desc":
          "Invite your clients to an online space to view your study, sign your quote, and track the progress of their case. Your clients can enter information and documents.",
        "login-registration-pages-title":
          "Login and Registration Pages on Website",
        "login-registration-pages-desc":
          "Invite your clients to log into their white label client portal from your website.",
        "seo-title": "Search Engine Optimization and SEO",
        "seo-desc":
          "Fill in the customizable profile 'Our establishment' available on studies and in our directory.",
        "online-messaging-title": "Online Messaging",
        "online-messaging-desc":
          "Our team responds within minutes with written records.",
        "phone-support-title": "Phone Support",
        "phone-support-desc":
          "Our team is always available to assist you with your procedures.",
        "email-title": "Email",
        "email-desc":
          "Our team is available by email to facilitate your communications with third parties.",
        "video-conference-appointments-title": "Video Conference Appointments",
        "video-conference-appointments-desc":
          "Our team is here to listen and help you develop your follow-up together.",
        "file-transfer-title": "File Transfer",
        "file-transfer-desc":
          "Our IT teams assist you in migrating data and files to our system so you can manage operations.",
      },
    },
    NATIONAL: {

      hero: {
       "icon-label": "Case Studies",
        "discover-solutions": "Discover Solutions",
      "book-demo": "Book a Demo",
        "name-orange": "National player",
          "name-black": "seeking operational excellence",
          "description": "Are you a nationwide operator handling numerous projects across France? Discover how to reduce your cost per watt with digital solutions."
      },
      works: {
        heading: "How it works",
        title:
          "Digital study office adapted for management across France.",

        steps: {
          step1: {
            title: "Account opening",
            heading:
              "No commitment. Quick and free online registration. Then, customize your colors, company presentation, and brand image.",
          },
          step2: {
            title: "File transfer",
            heading:
              "Our IT teams assist you in migrating data and files to our system so you can manage operations.",
          },
          step3: {
            title: "Starting Steering Committee",
            heading:
              "Service organization around detailed reports and bi-monthly meetings with a single point of contact and project managers.",
          },
        },
      },
      "product-listing": {
        "networking-title": "Networking",
        "networking-desc":
          "Discover potential clients directly from your account, without paying commissions to commercial intermediaries.",
        "project-management-assistance-title": "Project Management Assistance",
        "project-management-assistance-desc":
          "The project manager sets the price and materials. Benefit from the technical visit and electronic signature tools to accelerate your sales.",
        "3d-sizing-tool-title": "3D Sizing Tool",
        "3d-sizing-tool-desc":
          "We have integrated Aurora Solar to provide you with the most powerful and technically accurate photovoltaic yield solution.",
        "shading-masks-compliant-with-rge-audit-title":
          "Shading Masks Compliant with RGE Audit",
        "shading-masks-compliant-with-rge-audit-desc":
          "Opt for the precision of 0.10m LIDAR in 3D to perform sunlight assessments in kWh/m² throughout France.",
        "technical-design-with-pv-syst-title": "Technical Design with PV SYST",
        "technical-design-with-pv-syst-desc":
          "The electrical engineers from our study office carry out the design of inverters and Pnom, P50-P95 optimization for projects.",
        "enedis-api-title": "Enedis API and Linky Meter Data",
        "enedis-api-desc":
          "We have integrated Aurora Solar to offer you the most powerful and technically accurate photovoltaic production solution.",
        "self-consumption-or-full-sale-studies-title":
          "Self-consumption or full-sale studies",
        "self-consumption-or-full-sale-studies-desc":
          "Offer extraordinary and complete studies, with an unlimited number of techno-financial proposals per study.",
        "virtual-battery-comparison-studies-title":
          "Virtual Battery Comparison Studies",
        "virtual-battery-comparison-studies-desc":
          "Compare the profitability of the EDF OA contract with virtual battery providers MyLight Systems, Urban Solar Energy, JPME.",
        "physical-battery-studies-title": "Physical Battery Studies",
        "physical-battery-studies-desc":
          "Add a physical battery model and technical sheet for hourly analysis of self-consumption and storage profitability.",
        "techno-financial-profitability-studies-title":
          "Techno-financial Profitability Studies",
        "techno-financial-profitability-studies-desc":
          "Define the business plan for the budget. Calculate financial forecasts over 25 years. Analyze the cash flow table for the investment over the life of the project.",
        "watch-the-edf-oa-purchase-tariff-title":
          "Watch the EDF OA purchase tariff in mainland France and ZNI",
        "watch-the-edf-oa-purchase-tariff-desc":
          "The state premiums and purchase tariffs of our studies are updated every quarter with the new tariff decrees from the CRE.",
        "comparison-of-purchase-tariffs-by-t-0-title":
          "Comparison of purchase tariffs by T-0",
        "comparison-of-purchase-tariffs-by-t-0-desc":
          "Compare the profitability of photovoltaic plants with state aid across different quarters to speed up your sales.",
        "credit-and-financing-simulator-title":
          "Credit and Financing Simulator",
        "credit-and-financing-simulator-desc":
          "Analyze the investment in cash, through a loan, or through roof leasing - directly integrated into the study.",
        "enedis-costs-simulator-title": "Enedis Costs Simulator",
        "enedis-costs-simulator-desc":
          "Estimate the costs of reinforcing the electrical network based on Enedis' billing scale. The only alternative to the PTF in France.",
        "all-support-formats-in-white-label-title":
          "All support formats in white label",
        "all-support-formats-in-white-label-desc":
          "Studies in interactive mini-site, PDF, spreadsheet, or mobile format.",
        "quotes-with-integrated-materials-title":
          "Quotes with integrated materials",
        "quotes-with-integrated-materials-desc":
          "Prepare your quotes from a database with thousands of modules, inverters, enclosures, batteries.",
        "accounting-numbering-of-quotes-title":
          "Accounting numbering of quotes",
        "accounting-numbering-of-quotes-desc":
          "Also customize your colors, your general terms and conditions, technical sheets, VAT certificate, and much more for your quotes.",
        "legally-compliant-electronic-signatures-title":
          "Legally compliant electronic signatures",
        "legally-compliant-electronic-signatures-desc":
          "Write and have your quotes signed online, with your references and mentions. Access your quotes and documents in your account.",
        "accounting-invoices-for-deposits-and-balances-title":
          "Accounting invoices for deposits and balances",
        "accounting-invoices-for-deposits-and-balances-desc":
          "Define your deposits, manage your transactions, add your invoices, and much more.",
        "secure-payments-by-bank-transfer-or-credit-card-title":
          "Secure payments by bank transfer or credit card",
        "secure-payments-by-bank-transfer-or-credit-card-desc":
          "Receive your payments via simple payment pages, with several payment methods, integrated into your quotes and invoices.",
        "10000-product-references-title":
          "10,000+ product references: modules, inverters, cabinets, batteries ...",
        "10000-product-references-desc":
          "Choose the product model from our real-time updated database. Save your kits and equipment lists for reduced quote and procedure input.",
        "project-material-lists-title": "Project material lists",
        "project-material-lists-desc":
          "Automatic planning of product and equipment lists for photovoltaic installations.",
        "technical-datasheets-compliance-certificates-and-carbon-footprint-report-title":
          "Technical datasheets, Compliance Certificates, and Carbon Footprint Report",
        "technical-datasheets-compliance-certificates-and-carbon-footprint-report-desc":
          "Our database contains thousands of up-to-date documents for your procedures.",
        "professional-technical-documentation-title":
          "Professional technical documentation",
        "professional-technical-documentation-desc":
          "Automatically generate wiring diagrams, Strapping certificates, and Execution plans.",
        "material-ordering-title": "Material ordering",
        "material-ordering-desc":
          "Request quotes from manufacturers and distributors to find competitive prices online.",
        "urban-planning-authorization-requests-title":
          "Urban Planning Authorization Requests",
        "urban-planning-authorization-requests-desc":
          "Filing the Preliminary Declaration or Building Permit for your photovoltaic installation with the relevant local authorities for approval at the town hall.",
        "gracious-appeal-and-daact-title": "Gracious Appeal and DAACT",
        "gracious-appeal-and-daact-desc":
          "Filing the DAACT for your photovoltaic installation with the relevant local authorities. In case of refusal, filing the gracious appeal.",
        "communication-with-architects-title": "Communication with Architects",
        "communication-with-architects-desc":
          "Conducting PLU/RNU feasibility studies, preparing graphic documents, architect certification, and submission at the town hall as required by architect regulations.",
        "building-permit-expiry-title": "Building Permit Expiry",
        "building-permit-expiry-desc":
          "Filing the DOC, then conducting notary reports, EDDV, and DAACT for your photovoltaic installation with the relevant local authorities.",
        "preliminary-declarations-small-land-title":
          "Preliminary Declarations 'Small Land'",
        "preliminary-declarations-small-land-desc":
          "Conducting PLU/RNU feasibility studies, preparing the agricultural form, case-by-case study, and submitting the Preliminary Declaration at the town hall.",
        "connection-requests-title": "Connection Requests",
        "connection-requests-desc":
          "Obtaining the CAE contract and commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "low-or-medium-voltage-connection-requests-title":
          "Low or Medium Voltage Connection Requests",
        "low-or-medium-voltage-connection-requests-desc":
          "Obtaining the PTF and CACS-i contract, then commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "collective-self-consumption-connection-requests-title":
          "Collective Self-Consumption Connection Requests",
        "collective-self-consumption-connection-requests-desc":
          "Our specialized partner is directly integrated into your account for community creation and PMO. Conducting CAE requests and distribution keys.",
        "consuel-certificate-visa-requests-title":
          "Consuel Certificate Visa Requests",
        "consuel-certificate-visa-requests-desc":
          "Filing the SC144-A, SC144-B, or SC144-C, preparing the one-line electrical diagram, then submitting the Blue or Violet Conformity Certificate (for batteries).",
        "control-office-and-consuel-visa-title":
          "Control Office and Consuel Visa",
        "control-office-and-consuel-visa-desc":
          "Technical communication with accredited organizations and obtaining the DRE conformity report. Preparing the SC144, one-line electrical diagram, and submitting the Blue or Violet AC.",
        "sending-within-48-hours-on-a-large-scale-title":
          "Sending within 48 hours on a large scale",
        "sending-within-48-hours-on-a-large-scale-desc":
          "Our pioneering automation and robots generate documents and fill out online forms.",
        "real-time-dated-tracking-title": "Real-time dated tracking",
        "real-time-dated-tracking-desc":
          "Your secretariat can quickly initiate and then track the progress of procedures on the same software.",
        "processing-requests-for-additional-documents-title":
          "Processing requests for additional documents",
        "processing-requests-for-additional-documents-desc":
          "Our study office processes requests for additional documents to unblock the files.",
        "follow-ups-with-administrative-bodies-title":
          "Follow-ups with administrative bodies",
        "follow-ups-with-administrative-bodies-desc":
          "Our project managers follow up with administrative bodies to reduce processing times.",
        "edf-purchase-obligation-title": "EDF Purchase Obligation",
        "edf-purchase-obligation-desc":
          "Carrying out the procedure with EDF OA, automatic Affidavit or the S21 'ENR' Certificate with Carbon Footprint (for systems over 100 kWc).",
        "arpe-procedure-title": "ARPE Procedure",
        "arpe-procedure-desc":
          "Carrying out the procedure with an alternative buyer - Octopus Energie, Ekwateur, etc. - for PPE contracts and other purchase agreements.",
        "virtual-battery-procedure-title": "Virtual Battery Approach",
        "virtual-battery-procedure-desc":
          "Carrying out the procedure with one of the three virtual battery providers - MyLight Systems, Urban Solar Energy, JPME - for virtual storage.",
        "financing-profitability-report-title":
          "Financing Profitability Report",
        "financing-profitability-report-desc":
          "Analyze the financing of the photovoltaic investment based on energy yield, from an hourly kWh calculation.",
        "monthly-payment-savings-effort-calculation-title":
          "Monthly Payment and Savings Effort Calculation",
        "monthly-payment-savings-effort-calculation-desc":
          "Compare the financing of the photovoltaic investment, whether in cash or through a bank loan, integrating your OPEX and EBITDA.",
        "solar-subscription-title": "Solar Subscription",
        "solar-subscription-desc":
          "Our partner specializing in subscriptions is directly integrated into your account. Make solar subscription financing requests from the study stage.",
        "creation-management-spvs-title": "Creation and Management of SPVs",
        "creation-management-spvs-desc":
          "Your team organizes project portfolios by SPV directly from your account. Our project managers can create the SIRET numbers and assist with notaries.",
        "mobile-assistance-for-technical-visits-title":
          "Mobile Assistance for Technical Visits",
        "mobile-assistance-for-technical-visits-desc":
          "Your technical sales representatives can easily fill in client forms and upload photos and information while on the move.",
        "material-planning-title": "Material Planning",
        "material-planning-desc":
          "Automatic inventory lists of equipment available per site and per flow, for controlling material and orders.",
        "integrated-calendar-title": "Integrated Calendar",
        "integrated-calendar-desc":
          "Each team member has access, from their own account, to the company calendar for planning travels and installations. Add Google Calendar or Microsoft Outlook.",
        "subcontractor-management-for-installers-title":
          "Subcontractor Management for Installers",
        "subcontractor-management-for-installers-desc":
          "Your technical documents can be shared directly from your account with your project partners, to facilitate installation, Consuel, and Control Bureau.",
        "client-relations-and-crm-title": "Client Relations and CRM",
        "client-relations-and-crm-desc":
          "Client assignments, comment space on client forms, file storage. Customizable automatic email notifications.",
        "team-access-and-roles-title": "Team Access and Roles",
        "team-access-and-roles-desc":
          "Sales, secretarial, and technical teams can each customize their interface. Managers have access to payments. Accounting has email access to invoices.",
        "integration-with-business-referrers-title":
          "Integration with Business Referrers",
        "integration-with-business-referrers-desc":
          "Business referrers only have access to their clients. Information visibility is customizable.",
        "customizable-dashboard-title": "Customizable Dashboard",
        "customizable-dashboard-desc":
          "Each team member can adjust their dashboard. Manage your sizing, studies, administrative procedures, and construction in one place.",
        "sales-cycle-tracking-and-reports-title":
          "Sales Cycle Tracking and Reports",
        "sales-cycle-tracking-and-reports-desc":
          "View key sales figures and activity in real-time. Our platform generates statistics of your projects. Receive automatic reports by email.",
        "data-export-title": "Data Export",
        "data-export-desc":
          "Need to analyze data in Excel? Need to secure the data? Export all tables and files in CSV format.",
        "automatic-as-built-files-title": "Automatic As-Built Files (DOE)",
        "automatic-as-built-files-desc":
          "Generate DOE reports in PDF or web format, shareable with clients and third parties for work acceptance reports and insurance audits.",
        "maintenance-contracts-title": "Maintenance Contracts",
        "maintenance-contracts-desc":
          "Help your clients schedule and pay for after-sales services (SAV) and maintenance contracts through online payment pages.",
        "white-label-100-title": "100% White Label",
        "white-label-100-desc":
          "Add your logo and main colors, buttons, and graphics. Customize the interfaces. Enhance your brand.",
        "white-label-client-portal-title": "White Label Client Portal",
        "white-label-client-portal-desc":
          "Invite your clients to an online space to view your study, sign your quote, and track the progress of their case. Your clients can enter information and documents.",
        "login-and-registration-pages-title":
          "Login and Registration Pages on Website",
        "login-and-registration-pages-desc":
          "Invite your clients to log into their white label client portal from your website.",
        "seo-title": "Search Engine Optimization and SEO",
        "seo-desc":
          "Fill in the customizable profile 'Our establishment' available on studies and in our directory.",
        "online-messaging-title": "Online Messaging",
        "online-messaging-desc":
          "Our team responds within minutes with written records.",
        "phone-support-title": "Phone Support",
        "phone-support-desc":
          "Our team is always available to assist you with your procedures.",
        "email-title": "Email",
        "email-desc":
          "Our team is available by email to facilitate your communications with third parties.",
        "video-conference-appointments-title": "Video Conference Appointments",
        "video-conference-appointments-desc":
          "Our team is here to listen and help you develop your follow-up together.",
        "file-transfer-title": "File Transfer",
        "file-transfer-desc":
          "Our IT teams assist you in migrating data and files to our system so you can manage operations.",
        "bi-monthly-steering-committee-title": "Bi-monthly Steering Committee",
        "bi-monthly-steering-committee-desc":
          "Service organization around detailed reports and meetings every 15 days with a single point of contact and project managers.",
        "exclusive-access-title": "Exclusive Access",
        "exclusive-access-desc": "Your key teams are invited to join.",
      },
    },
    SELF_INSTALLATION: {
      hero: {
        "icon-label": "Case Studies",
        "discover-solutions": "Discover Solutions",
        "book-demo": "Book a Demo",
        "name-orange": "Online Brand",
        "name-black": "offering services",
        "description": "Are you a company offering a plug & play (solar kit) or DIY (Do It Yourself) solution across France? Discover how to sell online support services."
      },
      works: {
        heading: "How it works",
        title:
          "Digital study office adapted for innovative and 100% online sales.",

        steps: {
          step1: {
            title: "Account personalization",
            heading:
              "Quick and free online registration. No commitment. Then, customize your colors, company presentation, and brand image.",
          },
          step2: {
            title: "White-label integration",
            heading:
              "Our IT teams assist you in integrating the registration pages, login pages, and photovoltaic customer portal into your website.",
          },
          step3: {
            title: "Add services a la carte",
            heading:
              "Need to do layouts? Full studies with quotes? Or municipal agreements? Use only what you need.",
          },
        },
      },
      "product-listing": {
        "networking-title": "Networking",
        "networking-desc":
          "Discover potential clients directly from your account, without paying commissions to business introducers.",
        "project-management-assistance-title": "Project Management Assistance",
        "project-management-assistance-desc":
          "The project manager sets the price and materials. Benefit from the technical visit and electronic signature tools to accelerate your sales.",
        "3d-sizing-tool-title": "3D Sizing Tool",
        "3d-sizing-tool-desc":
          "We have integrated Aurora Solar to provide you with the most powerful and technically accurate photovoltaic yield solution.",
        "shading-masks-compliant-with-rge-audit-title":
          "Shading Masks Compliant with RGE Audit",
        "shading-masks-compliant-with-rge-audit-desc":
          "Opt for the precision of 0.10m LIDAR in 3D to perform sunlight assessments in kWh/m² throughout France.",
        "enedis-api-title": "Enedis API and Linky Meter Data",
        "enedis-api-desc":
          "We have integrated Aurora Solar to offer you the most powerful and technically accurate photovoltaic production solution.",
        "self-consumption-or-full-sale-studies-title":
          "Self-consumption or full-sale studies",
        "self-consumption-or-full-sale-studies-desc":
          "Offer extraordinary and complete studies, with an unlimited number of techno-financial proposals per study.",
        "virtual-battery-comparison-studies-title":
          "Virtual Battery Comparison Studies",
        "virtual-battery-comparison-studies-desc":
          "Compare the profitability of the EDF OA contract with virtual battery providers MyLight Systems, Urban Solar Energy, JPME.",
        "physical-battery-studies-title": "Physical Battery Studies",
        "physical-battery-studies-desc":
          "Add a physical battery model and technical sheet for hourly analysis of self-consumption and storage profitability.",
        "techno-financial-profitability-studies-title":
          "Techno-financial Profitability Studies",
        "techno-financial-profitability-studies-desc":
          "Define the business plan for the budget. Calculate financial forecasts over 25 years. Analyze the cash flow table for the investment over the life of the project.",
        "watch-the-edf-oa-purchase-tariff-title":
          "Watch the EDF OA purchase tariff in mainland France and ZNI",
        "watch-the-edf-oa-purchase-tariff-desc":
          "The state premiums and purchase tariffs of our studies are updated every quarter with the new tariff decrees from the CRE.",
        "credit-and-financing-simulator-title":
          "Credit and Financing Simulator",
        "credit-and-financing-simulator-desc":
          "Analyze the investment in cash, through a loan, or through roof leasing - directly integrated into the study.",
        "all-support-formats-in-white-label-title":
          "All support formats in white label",
        "all-support-formats-in-white-label-desc":
          "Studies in interactive mini-site, PDF, spreadsheet, or mobile format.",
        "quotes-with-integrated-materials-title":
          "Quotes with integrated materials",
        "quotes-with-integrated-materials-desc":
          "Prepare your quotes from a database with thousands of modules, inverters, enclosures, batteries.",
        "accounting-numbering-of-quotes-title":
          "Accounting numbering of quotes",
        "accounting-numbering-of-quotes-desc":
          "Also customize your colors, your general terms and conditions, technical sheets, VAT certificate, and much more for your quotes.",
        "legally-compliant-electronic-signatures-title":
          "Legally compliant electronic signatures",
        "legally-compliant-electronic-signatures-desc":
          "Write and have your quotes signed online, with your references and mentions. Access your quotes and documents in your account.",
        "accounting-invoices-for-deposits-and-balances-title":
          "Accounting invoices for deposits and balances",
        "accounting-invoices-for-deposits-and-balances-desc":
          "Define your deposits, manage your transactions, add your invoices, and much more.",
        "secure-payments-by-bank-transfer-or-credit-card-title":
          "Secure payments by bank transfer or credit card",
        "secure-payments-by-bank-transfer-or-credit-card-desc":
          "Receive your payments via simple payment pages, with several payment methods, integrated into your quotes and invoices.",
        "10000-product-references-title":
          "10,000+ product references: modules, inverters, cabinets, batteries ...",
        "10000-product-references-desc":
          "Choose the product model from our real-time updated database. Save your kits and equipment lists for reduced quote and procedure input.",
        "project-material-lists-title": "Project material lists",
        "project-material-lists-desc":
          "Automatic planning of product and equipment lists for photovoltaic installations.",
        "technical-datasheets-compliance-certificates-carbon-footprint-report-title":
          "Technical datasheets, Compliance Certificates, and Carbon Footprint Report",
        "technical-datasheets-compliance-certificates-carbon-footprint-report-desc":
          "Our database contains thousands of up-to-date documents for your procedures.",
        "professional-technical-documentation-title":
          "Professional technical documentation",
        "professional-technical-documentation-desc":
          "Automatically generate wiring diagrams, Strapping certificates, and Execution plans.",
        "material-ordering-title": "Material ordering",
        "material-ordering-desc":
          "Request quotes from manufacturers and distributors to find competitive prices online.",
        "urban-planning-authorization-requests-title":
          "Urban Planning Authorization Requests",
        "urban-planning-authorization-requests-desc":
          "Filing the Preliminary Declaration or Building Permit for your photovoltaic installation with the relevant local authorities for approval at the town hall.",
        "gracious-appeal-and-daact-title": "Gracious Appeal and DAACT",
        "gracious-appeal-and-daact-desc":
          "Filing the DAACT for your photovoltaic installation with the relevant local authorities. In case of refusal, filing the gracious appeal.",
        "connection-requests-title": "Connection Requests",
        "connection-requests-desc":
          "Obtaining the CAE contract and commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "consuel-certificate-visa-requests-title":
          "Consuel Certificate Visa Requests",
        "consuel-certificate-visa-requests-desc":
          "Filing the SC144-A, SC144-B, or SC144-C, preparing the one-line electrical diagram, then submitting the Blue or Violet Conformity Certificate (for batteries).",
        "sending-within-48-hours-on-a-large-scale-title":
          "Sending within 48 hours on a large scale",
        "sending-within-48-hours-on-a-large-scale-desc":
          "Our pioneering automation and robots generate documents and fill out online forms.",
        "real-time-dated-tracking-title": "Real-time dated tracking",
        "real-time-dated-tracking-desc":
          "Your secretariat can quickly initiate and then track the progress of procedures on the same software.",
        "processing-requests-for-additional-documents-title":
          "Processing requests for additional documents",
        "processing-requests-for-additional-documents-desc":
          "Our study office processes requests for additional documents to unblock the files.",
        "follow-ups-with-administrative-bodies-title":
          "Follow-ups with administrative bodies",
        "follow-ups-with-administrative-bodies-desc":
          "Our project managers follow up with administrative bodies to reduce processing times.",
        "edf-purchase-obligation-title": "EDF Purchase Obligation",
        "edf-purchase-obligation-desc":
          "Carrying out the procedure with EDF OA, automatic Affidavit or the S21 'ENR' Certificate with Carbon Footprint (for systems over 100 kWc).",
        "cacsi-procedure-title": "CACSI Procedure",
        "cacsi-procedure-desc":
          "Obtaining the CACSI contract and commissioning your photovoltaic installation with the network operator Enedis or local grid operator ELD.",
        "arpe-procedure-title": "ARPE Procedure",
        "arpe-procedure-desc":
          "Carrying out the procedure with an alternative buyer - Octopus Energie, Ekwateur, etc. - for PPE contracts and other purchase agreements.",
        "virtual-battery-procedure-title": "Virtual Battery Approach",
        "virtual-battery-procedure-desc":
          "Carrying out the procedure with one of the three virtual battery providers - MyLight Systems, Urban Solar Energy, JPME - for virtual storage.",
        "financing-r-title": "Financing Profitability Report",
        "financing-r-desc":
          "Analyze the financing of the photovoltaic investment based on energy yield, from an hourly kWh calculation.",
        "monthly-payment-and-savings-effort-calculation-title":
          "Monthly Payment and Savings Effort Calculation",
        "monthly-payment-and-savings-effort-calculation-desc":
          "Compare the financing of the photovoltaic investment, whether in cash or through a bank loan, integrating your OPEX and EBITDA.",
        "material-planning-title": "Material Planning",
        "material-planning-desc":
          "Automatic inventory lists of equipment available per site and per flow, for controlling material and orders.",
        "integrated-calendar-title": "Integrated Calendar",
        "integrated-calendar-desc":
          "Each team member has access, from their own account, to the company calendar for planning travels and installations. Add Google Calendar or Microsoft Outlook.",
        "sharing-sites-with-installers-title": "Sharing Sites with Installers",
        "sharing-sites-with-installers-desc":
          "Your technical documents can be shared directly from your account with your project partners, to facilitate construction, Consuel, and Control Bureau.",
        "client-relations-and-crm-title": "Client Relations and CRM",
        "client-relations-and-crm-desc":
          "Client assignments, comment space on client forms, file storage. Customizable automatic email notifications.",
        "team-access-and-roles-title": "Team Access and Roles",
        "team-access-and-roles-desc":
          "Sales, secretarial, and technical teams can each customize their interface. Managers have access to payments. Accounting has email access to invoices.",
        "integration-with-business-referrers-title":
          "Integration with Business Referrers",
        "integration-with-business-referrers-desc":
          "Business referrers only have access to their clients. Information visibility is customizable.",
        "customizable-dashboard-title": "Customizable Dashboard",
        "customizable-dashboard-desc":
          "Each team member can adjust their dashboard. Manage your sizing, studies, administrative procedures, and construction in one place.",
        "sales-cycle-tracking-and-reports-title":
          "Sales Cycle Tracking and Reports",
        "sales-cycle-tracking-and-reports-desc":
          "View key sales figures and activity in real-time. Our platform generates statistics of your projects. Receive automatic reports by email.",
        "data-export-title": "Data Export",
        "data-export-desc":
          "Need to analyze data in Excel? Need to secure the data? Export all tables and files in CSV format.",
        "automatic-as-built-files-title": "Automatic As-Built Files (DOE)",
        "automatic-as-built-files-desc":
          "Generate DOE reports in PDF or web format, shareable with clients and third parties for work acceptance reports and insurance audits.",
        "maintenance-contracts-title": "Maintenance Contracts",
        "maintenance-contracts-desc":
          "Help your clients schedule and pay for after-sales services (SAV) and maintenance contracts through online payment pages.",
        "white-label-title": "100% White Label",
        "white-label-desc":
          "Add your logo and main colors, buttons, and graphics. Customize the interfaces. Enhance your brand.",
        "white-label-client-portal-title": "White Label Client Portal",
        "white-label-client-portal-desc":
          "Invite your clients to an online space to view your study, sign your quote, and track the progress of their case. Your clients can enter information and documents.",
        "login-and-registration-pages-on-website-title":
          "Login and Registration Pages on Website",
        "login-and-registration-pages-on-website-desc":
          "Invite your clients to log into their white label client portal from your website.",
        "seo-title": "Search Engine Optimization and SEO",
        "seo-desc":
          "Fill in the customizable profile 'Our establishment' available on studies and in our directory.",
        "online-messaging-title": "Online Messaging",
        "online-messaging-desc":
          "Our team responds within minutes with written records.",
        "phone-support-title": "Phone Support",
        "phone-support-desc":
          "Our team is always available to assist you with your procedures.",
        "email-title": "Email",
        "email-desc":
          "Our team is available by email to facilitate your communications with third parties.",
        "video-conference-appointments-title": "Video Conference Appointments",
        "video-conference-appointments-desc":
          "Our team is here to listen and help you develop your follow-up together.",
        "file-transfer-title": "File Transfer",
        "file-transfer-desc":
          "Our IT teams assist you in migrating data and files to our system so you can manage operations.",
      },
    },
    FRANCHISE: {
      hero: {
      "icon-label": "Case Studies",
        "discover-solutions": "Discover Solutions",
      "book-demo": "Book a Demo",
       "name-orange": "Franchise network",
          "name-black": "with high added value",
          "description": "Are you a company operating in franchise across the entire national territory, interested in the projects of the franchise network? Discover how to strengthen your offer."
      },
      works: {
        heading: "How it works",
        title:
          "Digital study office designed for photovoltaic teams.",

        steps: {
          step1: {
            title: "Account opening",
            heading:
              "No commitment. Quick and free online registration. Then, customize your colors, company presentation, and brand image.",
          },
          step2: {
            title: "Franchise invitation",
            heading:
              "Our teams assist you in IT integrations. Your franchises are trained by our teams in IT solutions.",
          },
          step3: {
            title: "Service sharing",
            heading:
              "Buy IT solutions a la carte with the benefits of network strength. Thus, gain added value for your franchises.",
          },
        },
      },
      "product-listing": {
        "networking-title": "Networking",
        "networking-desc":
          "Discover potential clients directly from your account, without paying commissions to business introducers.",
        "project-management-assistance-title": "Project Management Assistance",
        "project-management-assistance-desc":
          "The project manager sets the price and materials. Benefit from the technical visit and electronic signature tools to accelerate your sales.",
        "3d-sizing-tool-title": "3D Sizing Tool",
        "3d-sizing-tool-desc":
          "We have integrated Aurora Solar to provide you with the most powerful and technically accurate photovoltaic yield solution.",
        "shading-masks-compliant-with-rge-audit-title":
          "Shading Masks Compliant with RGE Audit",
        "shading-masks-compliant-with-rge-audit-desc":
          "Opt for the precision of 0.10m LIDAR in 3D to perform sunlight assessments in kWh/m² throughout France.",
        "enedis-api-and-linky-meter-data-title":
          "Enedis API and Linky Meter Data",
        "enedis-api-and-linky-meter-data-desc":
          "We have integrated Aurora Solar to offer you the most powerful and technically accurate photovoltaic production solution.",
        "self-consumption-or-full-sale-studies-title":
          "Self-consumption or full-sale studies",
        "self-consumption-or-full-sale-studies-desc":
          "Offer extraordinary and complete studies, with an unlimited number of techno-financial proposals per study.",
        "virtual-battery-comparison-studies-title":
          "Virtual Battery Comparison Studies",
        "virtual-battery-comparison-studies-desc":
          "Compare the profitability of the EDF OA contract with virtual battery providers MyLight Systems, Urban Solar Energy, JPME.",
        "physical-battery-studies-title": "Physical Battery Studies",
        "physical-battery-studies-desc":
          "Add a physical battery model and technical sheet for hourly analysis of self-consumption and storage profitability.",
        "techno-financial-profitability-studies-title":
          "Techno-financial Profitability Studies",
        "techno-financial-profitability-studies-desc":
          "Define the business plan for the budget. Calculate financial forecasts over 25 years. Analyze the cash flow table for the investment over the life of the project.",
        "watch-the-edf-oa-purchase-tariff-title":
          "Watch the EDF OA purchase tariff in mainland France and ZNI",
        "watch-the-edf-oa-purchase-tariff-desc":
          "The state premiums and purchase tariffs of our studies are updated every quarter with the new tariff decrees from the CRE.",
        "credit-and-financing-simulator-title":
          "Credit and Financing Simulator",
        "credit-and-financing-simulator-desc":
          "Analyze the investment in cash, through a loan, or through roof leasing - directly integrated into the study.",
        "all-support-formats-in-white-label-title":
          "All support formats in white label",
        "all-support-formats-in-white-label-desc":
          "Studies in interactive mini-site, PDF, spreadsheet, or mobile format.",
        "quotes-with-integrated-materials-title":
          "Quotes with integrated materials",
        "quotes-with-integrated-materials-desc":
          "Prepare your quotes from a database with thousands of modules, inverters, enclosures, batteries.",
        "accounting-numbering-of-quotes-title":
          "Accounting numbering of quotes",
        "accounting-numbering-of-quotes-desc":
          "Also customize your colors, your general terms and conditions, technical sheets, VAT certificate, and much more for your quotes.",
        "legally-compliant-electronic-signatures-title":
          "Legally compliant electronic signatures",
        "legally-compliant-electronic-signatures-desc":
          "Write and have your quotes signed online, with your references and mentions. Access your quotes and documents in your account.",
        "accounting-invoices-for-deposits-and-balances-title":
          "Accounting invoices for deposits and balances",
        "accounting-invoices-for-deposits-and-balances-desc":
          "Define your deposits, manage your transactions, add your invoices, and much more.",
        "secure-payments-by-bank-transfer-or-credit-card-title":
          "Secure payments by bank transfer or credit card",
        "secure-payments-by-bank-transfer-or-credit-card-desc":
          "Receive your payments via simple payment pages, with several payment methods, integrated into your quotes and invoices.",
        "product-references-title":
          "10,000+ product references: modules, inverters, cabinets, batteries ...",
        "product-references-desc":
          "Choose the product model from our real-time updated database. Save your kits and equipment lists for reduced quote and procedure input.",
        "project-material-lists-title": "Project material lists",
        "project-material-lists-desc":
          "Automatic planning of product and equipment lists for photovoltaic installations.",
        "technical-datasheets-compliance-certificates-carbon-footprint-report-title":
          "Technical datasheets, Compliance Certificates, and Carbon Footprint Report",
        "technical-datasheets-compliance-certificates-carbon-footprint-report-desc":
          "Our database contains thousands of up-to-date documents for your procedures.",
        "professional-technical-documentation-title":
          "Professional technical documentation",
        "professional-technical-documentation-desc":
          "Automatically generate wiring diagrams, Strapping certificates, and Execution plans.",
        "material-ordering-title": "Material ordering",
        "material-ordering-desc":
          "Request quotes from manufacturers and distributors to find competitive prices online.",
        "urban-planning-authorization-requests-title":
          "Urban Planning Authorization Requests",
        "urban-planning-authorization-requests-desc":
          "Filing the Preliminary Declaration or Building Permit for your photovoltaic installation with the relevant local authorities for approval at the town hall.",
        "gracious-appeal-and-daact-title": "Gracious Appeal and DAACT",
        "gracious-appeal-and-daact-desc":
          "Filing the DAACT for your photovoltaic installation with the relevant local authorities. In case of refusal, filing the gracious appeal.",
        "communication-with-architects-title": "Communication with Architects",
        "communication-with-architects-desc":
          "Conducting PLU/RNU feasibility studies, preparing graphic documents, architect certification, and submission at the town hall as required by architect regulations.",
        "connection-requests-title": "Connection Requests",
        "connection-requests-desc":
          "Obtaining the CAE contract and commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "low-or-medium-voltage-connection-requests-title":
          "Low or Medium Voltage Connection Requests",
        "low-or-medium-voltage-connection-requests-desc":
          "Obtaining the PTF and CACS-i contract, then commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "collective-self-consumption-connection-requests-title":
          "Collective Self-Consumption Connection Requests",
        "collective-self-consumption-connection-requests-desc":
          "Our specialized partner is directly integrated into your account for community creation and PMO. Conducting CAE requests and distribution keys.",
        "consuel-certificate-visa-requests-title":
          "Consuel Certificate Visa Requests",
        "consuel-certificate-visa-requests-desc":
          "Filing the SC144-A, SC144-B, or SC144-C, preparing the one-line electrical diagram, then submitting the Blue or Violet Conformity Certificate (for batteries).",
        "control-office-and-consuel-visa-title":
          "Control Office and Consuel Visa",
        "control-office-and-consuel-visa-desc":
          "Technical communication with accredited organizations and obtaining the DRE conformity report. Preparing the SC144, one-line electrical diagram, and submitting the Blue or Violet AC.",
        "sending-within-48-hours-on-a-large-scale-title":
          "Sending within 48 hours on a large scale",
        "sending-within-48-hours-on-a-large-scale-desc":
          "Our pioneering automation and robots generate documents and fill out online forms.",
        "real-time-dated-tracking-title": "Real-time dated tracking",
        "real-time-dated-tracking-desc":
          "Your secretariat can quickly initiate and then track the progress of procedures on the same software.",
        "processing-requests-for-additional-documents-title":
          "Processing requests for additional documents",
        "processing-requests-for-additional-documents-desc":
          "Our study office processes requests for additional documents to unblock the files.",
        "follow-ups-with-administrative-bodies-title":
          "Follow-ups with administrative bodies",
        "follow-ups-with-administrative-bodies-desc":
          "Our project managers follow up with administrative bodies to reduce processing times.",
        "edf-purchase-obligation-title": "EDF Purchase Obligation",
        "edf-purchase-obligation-desc":
          "Carrying out the procedure with EDF OA, automatic Affidavit or the S21 'ENR' Certificate with Carbon Footprint (for systems over 100 kWc).",
        "virtual-battery-procedure-title": "Virtual Battery Approach",
        "virtual-battery-procedure-desc":
          "Carrying out the procedure with one of the three virtual battery providers - MyLight Systems, Urban Solar Energy, JPME - for virtual storage.",
        "financing-profitability-report-title":
          "Financing Profitability Report",
        "financing-profitability-report-desc":
          "Analyze the financing of the photovoltaic investment based on energy yield, from an hourly kWh calculation.",
        "monthly-payment-and-savings-effort-calculation-title":
          "Monthly Payment and Savings Effort Calculation",
        "monthly-payment-and-savings-effort-calculation-desc":
          "Compare the financing of the photovoltaic investment, whether in cash or through a bank loan, integrating your OPEX and EBITDA.",
        "solar-subscription-title": "Solar Subscription",
        "solar-subscription-desc":
          "Our partner specializing in subscriptions is directly integrated into your account. Make solar subscription financing requests from the study stage.",
        "mobile-assistance-for-technical-visits-title":
          "Mobile Assistance for Technical Visits",
        "mobile-assistance-for-technical-visits-desc":
          "Your technical sales representatives can easily fill in client forms and upload photos and information while on the move.",
        "material-planning-title": "Material Planning",
        "material-planning-desc":
          "Automatic inventory lists of equipment available per site and per flow, for controlling material and orders.",
        "integrated-calendar-title": "Integrated Calendar",
        "integrated-calendar-desc":
          "Each team member has access, from their own account, to the company calendar for planning travels and installations. Add Google Calendar or Microsoft Outlook.",
        "sharing-sites-with-installers-title": "Sharing Sites with Installers",
        "sharing-sites-with-installers-desc":
          "Your technical documents can be shared directly from your account with your project partners, to facilitate construction, Consuel, and Control Bureau.",
        "client-relations-and-crm-title": "Client Relations and CRM",
        "client-relations-and-crm-desc":
          "Client assignments, comment space on client forms, file storage. Customizable automatic email notifications.",
        "team-access-and-roles-title": "Team Access and Roles",
        "team-access-and-roles-desc":
          "Sales, secretarial, and technical teams can each customize their interface. Managers have access to payments. Accounting has email access to invoices.",
        "integration-with-business-referrers-title":
          "Integration with Business Referrers",
        "integration-with-business-referrers-desc":
          "Business referrers only have access to their clients. Information visibility is customizable.",
        "customizable-dashboard-title": "Customizable Dashboard",
        "customizable-dashboard-desc":
          "Each team member can adjust their dashboard. Manage your sizing, studies, administrative procedures, and construction in one place.",
        "sales-cycle-tracking-and-reports-title":
          "Sales Cycle Tracking and Reports",
        "sales-cycle-tracking-and-reports-desc":
          "View key sales figures and activity in real-time. Our platform generates statistics of your projects. Receive automatic reports by email.",
        "data-export-title": "Data Export",
        "data-export-desc":
          "Need to analyze data in Excel? Need to secure the data? Export all tables and files in CSV format.",
        "automatic-as-built-files-title": "Automatic As-Built Files (DOE)",
        "automatic-as-built-files-desc":
          "Generate DOE reports in PDF or web format, shareable with clients and third parties for work acceptance reports and insurance audits.",
        "maintenance-contracts-title": "Maintenance Contracts",
        "maintenance-contracts-desc":
          "Help your clients schedule and pay for after-sales services (SAV) and maintenance contracts through online payment pages.",
        "white-label-title": "100% White Label",
        "white-label-desc":
          "Add your logo and main colors, buttons, and graphics. Customize the interfaces. Enhance your brand.",
        "white-label-client-portal-title": "White Label Client Portal",
        "white-label-client-portal-desc":
          "Invite your clients to an online space to view your study, sign your quote, and track the progress of their case. Your clients can enter information and documents.",
        "login-and-registration-pages-title":
          "Login and Registration Pages on Website",
        "login-and-registration-pages-desc":
          "Invite your clients to log into their white label client portal from your website.",
        "search-engine-optimization-title":
          "Search Engine Optimization and SEO",
        "search-engine-optimization-desc":
          "Fill in the customizable profile 'Our establishment' available on studies and in our directory.",
        "online-messaging-title": "Online Messaging",
        "online-messaging-desc":
          "Our team responds within minutes with written records.",
        "phone-support-title": "Phone Support",
        "phone-support-desc":
          "Our team is always available to assist you with your procedures.",
        "email-title": "Email",
        "email-desc":
          "Our team is available by email to facilitate your communications with third parties.",
        "video-conference-appointments-title": "Video Conference Appointments",
        "video-conference-appointments-desc":
          "Our team is here to listen and help you develop your follow-up together.",
        "file-transfer-title": "File Transfer",
        "file-transfer-desc":
          "Our IT teams assist you in migrating data and files to our system so you can manage operations.",
      },
    },
    DEVELOPER: {
      hero: {
        "icon-label": "Case Studies",
        "discover-solutions": "Discover Solutions",
      "book-demo": "Book a Demo",
        "name-orange": "Photovoltaic developer",
        "name-black": "looking to structure its growth",
        "description": "Are you a company managing the development of SPV projects? Discover how to structure development and construction with external partners."
      },
      works: {
        heading: "How it works",
        title:
          "Digital study office specializing in solar development in BT and HTA.",
        steps: {
          step1: {
            title: "Account opening",
            heading:
              "No commitment. Quick and free online registration. Then, complete the account with key company personnel.",
          },
          step2: {
            title: "File transfer",
            heading:
              "Our IT teams assist you in migrating data and files to our system to structure operations.",
          },
          step3: {
            title: "Starting Steering Committee",
            heading:
              "Service organization around detailed reports and bi-monthly meetings with a single contact person and project managers.",
          },
        },
      },
      "product-listing": {
        "button": "Learn More",
        "title": "Product Listing",
        "description": "Discover our range of products designed to simplify your photovoltaic installation process.",
        "networking-title": "Networking",
        "networking-desc":
          "Discover potential clients directly from your account, without paying commissions to business introducers.",
        "project-management-assistance-title": "Project Management Assistance",
        "project-management-assistance-desc":
          "The project manager sets the price and materials. Benefit from the technical visit and electronic signature tools to accelerate your sales.",
        "3d-sizing-tool-title": "3D Sizing Tool",
        "3d-sizing-tool-desc":
          "We have integrated Aurora Solar to provide you with the most powerful and technically accurate photovoltaic yield solution.",
        "shading-masks-compliant-with-rge-audit-title":
          "Shading Masks Compliant with RGE Audit",
        "shading-masks-compliant-with-rge-audit-desc":
          "Opt for the precision of 0.10m LIDAR in 3D to perform sunlight assessments in kWh/m² throughout France.",
        "technical-design-pv-syst-title": "Technical Design with PV SYST",
        "technical-design-pv-syst-desc":
          "The electrical engineers from our study office carry out the design of inverters and Pnom, P50-P95 optimization for projects.",
        "quotes-with-integrated-materials-title":
          "Quotes with integrated materials",
        "quotes-with-integrated-materials-desc":
          "Prepare your quotes from a database with thousands of modules, inverters, enclosures, batteries.",
        "accounting-numbering-quotes-title": "Accounting numbering of quotes",
        "accounting-numbering-quotes-desc":
          "Also customize your colors, your general terms and conditions, technical sheets, VAT certificate, and much more for your quotes.",
        "legally-compliant-electronic-signatures-title":
          "Legally compliant electronic signatures",
        "legally-compliant-electronic-signatures-desc":
          "Write and have your quotes signed online, with your references and mentions. Access your quotes and documents in your account.",
        "accounting-invoices-title":
          "Accounting invoices for deposits and balances",
        "accounting-invoices-desc":
          "Define your deposits, manage your transactions, add your invoices, and much more.",
        "secure-payments-title":
          "Secure payments by bank transfer or credit card",
        "secure-payments-desc":
          "Receive your payments via simple payment pages, with several payment methods, integrated into your quotes and invoices.",
        "product-references-title":
          "10,000+ product references: modules, inverters, cabinets, batteries ...",
        "product-references-desc":
          "Choose the product model from our real-time updated database. Save your kits and equipment lists for reduced quote and procedure input.",
        "project-material-lists-title": "Project material lists",
        "project-material-lists-desc":
          "Automatic planning of product and equipment lists for photovoltaic installations.",
        "technical-datasheets-title":
          "Technical datasheets, Compliance Certificates, and Carbon Footprint Report",
        "technical-datasheets-desc":
          "Our database contains thousands of up-to-date documents for your procedures.",
        "professional-technical-docs-title":
          "Professional technical documentation",
        "professional-technical-docs-desc":
          "Automatically generate wiring diagrams, Strapping certificates, and Execution plans.",
        "material-ordering-title": "Material ordering",
        "material-ordering-desc":
          "Request quotes from manufacturers and distributors to find competitive prices online.",
        "urban-planning-authorization-title":
          "Urban Planning Authorization Requests",
        "urban-planning-authorization-requests-desc":
          "Filing the Preliminary Declaration or Building Permit for your photovoltaic installation with the relevant local authorities for approval at the town hall.",
        "communication-with-architects-title": "Communication with Architects",
        "communication-with-architects-desc":
          "Conducting PLU/RNU feasibility studies, preparing graphic documents, architect certification, and submission at the town hall as required by architect regulations.",
        "building-permit-expiry-title": "Building Permit Expiry",
        "building-permit-expiry-desc":
          "Filing the DOC, then conducting notary reports, EDDV, and DAACT for your photovoltaic installation with the relevant local authorities.",
        "preliminary-declarations-small-land-title":
          "Preliminary Declarations 'Small Land'",
        "preliminary-declarations-small-land-desc":
          "Conducting PLU/RNU feasibility studies, preparing the agricultural form, case-by-case study, and submitting the Preliminary Declaration at the town hall.",
        "low-or-medium-voltage-connection-requests-title":
          "Low or Medium Voltage Connection Requests",
        "low-or-medium-voltage-connection-requests-desc":
          "Obtaining the PTF and CACS-i contract, then commissioning your photovoltaic installation with the electrical network operator Enedis or local ELD operator.",
        "collective-self-consumption-connection-requests-title":
          "Collective Self-Consumption Connection Requests",
        "collective-self-consumption-connection-requests-desc":
          "Our specialized partner is directly integrated into your account for community creation and PMO. Conducting CAE requests and distribution keys.",
        "control-office-and-consuel-visa-title":
          "Control Office and Consuel Visa",
        "control-office-and-consuel-visa-desc":
          "Technical communication with accredited organizations and obtaining the DRE conformity report. Preparing the SC144, one-line electrical diagram, and submitting the Blue or Violet AC.",
        "sending-within-48-hours-title":
          "Sending within 48 hours on a large scale",
        "sending-within-48-hours-desc":
          "Our pioneering automation and robots generate documents and fill out online forms.",
        "real-time-dated-tracking-title": "Real-time dated tracking",
        "real-time-dated-tracking-desc":
          "Your secretariat can quickly initiate and then track the progress of procedures on the same software.",
        "processing-requests-for-additional-documents-title":
          "Processing requests for additional documents",
        "processing-requests-for-additional-documents-desc":
          "Our study office processes requests for additional documents to unblock the files.",
        "follow-ups-with-administrative-bodies-title":
          "Follow-ups with administrative bodies",
        "follow-ups-with-administrative-bodies-desc":
          "Our project managers follow up with administrative bodies to reduce processing times.",
        "edf-purchase-obligation-title": "EDF Purchase Obligation",
        "edf-purchase-obligation-desc":
          "Carrying out the procedure with EDF OA, automatic Affidavit or the S21 'ENR' Certificate with Carbon Footprint (for systems over 100 kWc).",
        "arpe-procedure-title": "ARPE Procedure",
        "arpe-procedure-desc":
          "Carrying out the procedure with an alternative buyer - Octopus Energie, Ekwateur, etc. - for PPE contracts and other purchase agreements.",
        "financing-profitability-report-title":
          "Financing Profitability Report",
        "financing-profitability-report-desc":
          "Analyze the financing of the photovoltaic investment based on energy yield, from an hourly kWh calculation.",
        "cost-price-buyout-profitability-calculation-title":
          "Cost Price, Buyout, and Profitability Calculation",
        "cost-price-buyout-profitability-calculation-desc":
          "Analyze the financing of the photovoltaic investment and the lease, integrating your OPEX and IRR (Internal Rate of Return).",
        "creation-and-management-of-spvs-title":
          "Creation and Management of SPVs",
        "creation-and-management-of-spvs-desc":
          "Your team organizes project portfolios by SPV directly from your account. Our project managers can create the SIRET numbers and assist with notaries.",
        "mobile-assistance-for-technical-visits-title":
          "Mobile Assistance for Technical Visits",
        "mobile-assistance-for-technical-visits-desc":
          "Your technical sales representatives can easily fill in client forms and upload photos and information while on the move.",
        "material-planning-title": "Material Planning",
        "material-planning-desc":
          "Automatic inventory lists of equipment available per site and per flow, for controlling material and orders.",
        "integrated-calendar-title": "Integrated Calendar",
        "integrated-calendar-desc":
          "Each team member has access, from their own account, to the company calendar for planning travels and installations. Add Google Calendar or Microsoft Outlook.",
        "sharing-sites-with-installers-title": "Sharing Sites with Installers",
        "sharing-sites-with-installers-desc":
          "Your technical documents can be shared directly from your account with your project partners, to facilitate construction, Consuel, and Control Bureau.",
        "sharing-files-with-third-parties-title":
          "Sharing Files with Third Parties",
        "sharing-files-with-third-parties-desc":
          "Your administrative documents can be shared directly from your account with third parties you authorize, for audits in the context of third-party investment.",
        "client-relations-and-crm-title": "Client Relations and CRM",
        "client-relations-and-crm-desc":
          "Client assignments, comment space on client forms, file storage. Customizable automatic email notifications.",
        "team-access-and-roles-title": "Team Access and Roles",
        "team-access-and-roles-desc":
          "Sales, secretarial, and technical teams can each customize their interface. Managers have access to payments. Accounting has email access to invoices.",
        "integration-with-business-referrers-title":
          "Integration with Business Referrers",
        "integration-with-business-referrers-desc":
          "Business referrers only have access to their clients. Information visibility is customizable.",
        "customizable-dashboard-title": "Customizable Dashboard",
        "customizable-dashboard-desc":
          "Each team member can adjust their dashboard. Manage your sizing, studies, administrative procedures, and construction in one place.",
        "sales-cycle-tracking-and-reports-title":
          "Sales Cycle Tracking and Reports",
        "sales-cycle-tracking-and-reports-desc":
          "View key sales figures and activity in real-time. Our platform generates statistics of your projects. Receive automatic reports by email.",
        "data-export-title": "Data Export",
        "data-export-desc":
          "Need to analyze data in Excel? Need to secure the data? Export all tables and files in CSV format.",
        "automatic-as-built-files-doe-title": "Automatic As-Built Files (DOE)",
        "automatic-as-built-files-doe-desc":
          "Generate DOE reports in PDF or web format, shareable with clients and third parties for work acceptance reports and insurance audits.",
        "maintenance-contracts-title": "Maintenance Contracts",
        "maintenance-contracts-desc":
          "Help your clients schedule and pay for after-sales services (SAV) and maintenance contracts through online payment pages.",
        "white-label-title": "100% White Label",
        "white-label-desc":
          "Add your logo and main colors, buttons, and graphics. Customize the interfaces. Enhance your brand.",
        "white-label-client-portal-title": "White Label Client Portal",
        "white-label-client-portal-desc":
          "Invite your clients to an online space to view your study, sign your quote, and track the progress of their case. Your clients can enter information and documents.",
        "login-and-registration-pages-on-website-title":
          "Login and Registration Pages on Website",
        "login-and-registration-pages-on-website-desc":
          "Invite your clients to log into their white label client portal from your website.",
        "search-engine-optimization-and-seo-title":
          "Search Engine Optimization and SEO",
        "search-engine-optimization-and-seo-desc":
          "Fill in the customizable profile 'Our establishment' available on studies and in our directory.",
        "online-messaging-title": "Online Messaging",
        "online-messaging-desc":
          "Our team responds within minutes with written records.",
        "phone-support-title": "Phone Support",
        "phone-support-desc":
          "Our team is always available to assist you with your procedures.",
        "email-title": "Email",
        "email-desc":
          "Our team is available by email to facilitate your communications with third parties.",
        "video-conference-appointments-title": "Video Conference Appointments",
        "video-conference-appointments-desc":
          "Our team is here to listen and help you develop your follow-up together.",
        "file-transfer-title": "File Transfer",
        "file-transfer-desc":
          "Our IT teams assist you in migrating data and files to our system so you can manage operations.",
        "bi-monthly-steering-committee-title": "Bi-monthly Steering Committee",
        "bi-monthly-steering-committee-desc":
          "Service organization around detailed reports and meetings every 15 days with a single point of contact and project managers.",
        "exclusive-access-title": "Exclusive Access",
        "exclusive-access-desc": "Your key teams are invited to join.",
      },
    },
    landing:{
      title:"All-in-One Solution for Your Solar Activity",
      description:"Discover how projetsolaire.com is used by our hundreds of clients to grow their business.",
      "discover-solutions": "Discover Solutions",
      "learn-more":"Learn More",
      "service-client-360": "360° Customer Service",
      "service-client-360-desc":
          "Stay in control with our multi-channel customer service available all year round.",
      heading:{
        artisan: "Solar Artisan",
        regional: "Local SME",
        national: "National Actor",
        franchise: "National / Franchises",
        developer: "Photovoltaic Developer",
        "plug-and-play": "National / DIY & Plug & Play"
      }
    }
  },
    header: {
      "use-cases": {
        "title": "Case Studies",
        "description": "Discover how projetsolaire.com is used by our hundreds of clients to grow their business.",
        "artisan": {
          "name": "Solar Artisan",
          "description": "Local photovoltaic installer."
        },
        "pme-locale": {
          "name": "Local SME",
          "description": "Photovoltaic installer operating in multiple departments."
        },
        "national": {
          "name": "National Actor",
          "description": "An actor operating across France and handling many projects nationwide."
        },
        "plug-and-play": {
          "name": "DIY Kits and Plug & Play",
          "description": "Brand offering a plug & play (solar kit) or DIY (Do It Yourself) solution across France."
        },
        "franchise": {
          "name": "Franchise Network",
          "description": "Company operating in franchise across the entire national territory, interested in the projects of the franchise network."
        },
        "developer": {
          "name": "Photovoltaic Developer",
          "description": "Company managing the development of projects in SPV."
        }
      },
      products:{
      "title": "Tailored Solutions",
      "description": "Discover the all-in-one solution designed for professionals to optimize every step of the photovoltaic installation process.",
      sizing:{
        "product-name": "Solar Sizing",
        "description": "3D layout with shadow masks."
      },
      studies:{
        "product-name": "Photovoltaic Studies",
        "description": "Hourly analysis over 25 years of sizing."
      },
      qtv:{
        "product-name": "Quotations and App Technical Visit",
        "description": "Checklist, quotations, electronic signature, invoices, online payments."
      },
      procedures:{
        "product-name": "Administrative Procedures",
        "description": "Urban Planning management, Enedis, Consuel, EDF OA, and more."
      },
      stock:{
        "product-name": "Stocks and Materials",
        "description": "Product references, material lists, orders."
      },
      management:{
        "product-name": "Project Management",
        "description": "Team access and roles, integrated calendar, sharing with third parties."
      }
      },
    }
  },

  blog: {
    hero: {
      title: "Start learning solar",
      subtitle:
        "Discover how to go solar and read up on other peoples solar journeys.",
    },
    "installation-steps": {
      title: "How to go solar in France?",
      subtitle:
        "Below are the tutorials outlining how to go solar in France. All these steps can be completed online and get you a trusted installer with a competitive price.",
    },
    featured: {
      title: "Main articles",
      subtitle: "Browse through some of our most read articles.",
    },
    latest: {
      title: "New articles",
      subtitle:
        "Stay up to date on the latest articles from our independent editorial team.",
      by: "written by ",
    },
    series: {
      topics: "Topics",
      "topics-series": {
        stories: "Stories",
        "why-go-solar": "Why go solar",
        "how-to-go-solar": "How to go solar in France ?",
      },
    },
    popular: {
      "popular-on": "Popular on Projetsolaire",
      by: "By ",
    },
  },

  about: {
    "mission-statement":
      "<span class='opacity-50'>Our</span> mission <span class='opacity-50'>is</span><br /> to solve residential solar <br /> in France",
    intro: {
      title: "We enable solar projects to happen",
      description:
        "Our aim is to transform solar project installation, making it faster, cheaper, simpler... better!",
    },
    story: {
      title: "Our journey",
      "paragraph-1": ".",
      "paragraph-2": "",
      "paragraph-3": "",
      "paragraph-4": "",
      "paragraph-5": "",
      "paragraph-6":
        "Implantée en Provence, notre startup innovante compte trois cofondateurs-associés. À ce jour, nous n’avons jamais eu besoin de lever de l’argent ni de dépenser un seul euro en publicité. Au contraire, notre méthode c’est la satisfaction client et le bouche-à-oreille.",
      "paragraph-7": "",
      "paragraph-8": "",
    },
    stats: {
      title: "Acteur de l’indépendance énergétique de France.",
      description:
        "Notre plateforme communautaire réunit tous les porteurs de projets solaires avec les professionnels du photovoltaïque ; ensemble avec tous les acteurs de la transition énergétique - bureaux d’études, fournisseurs, financiers, municipalités, services publics.",
      "flagship-projects":
        "Nous nous sommes élancé dans le solaire lors du premier confinement.",
      "installers-on-our-platform":
        "Artisans sur notre plateforme communautaire.",
      "solar-projects-enabled": "Projets solaires propulsés.",
      followers: "Followers",
    },
    team: {
      title: "L’équipe derrière la technologie",
      description:
        "We’re a dynamic group of individuals who are passionate about what we do and dedicated to delivering the best results for our clients.",
      people: {
        "person-1": {
          name: "Yann",
          role: "Operations lead & Co-founder",
          bio: "Yann Vermeil Schremmer continue d'imaginer de nouveaux modes de fonctionnement en interne, puis coordonne les divers interlocuteurs externes pour allier au mieux l’entreprenariat avec l’environnement.",
        },
        "person-2": {
          name: "Maarten",
          role: "CTO & Co-founder",
          bio: "Maarten Elgar développe la technologie qui aide les foyers à se mettre à l'énergie solaire en leur permettant d'obtenir des devis d'installateurs de confiance, puis de faire réaliser le reste du parcours en ligne.",
        },
        "person-3": {
          name: "Georges",
          role: "Sales lead & Co-founder",
          bio: "Georges Séraphim-André Nevsky aide les françaises et de français à découvrir leur potentiel solaire puis définir leur projet sur-mesure. Il aide les artisans photovoltaïques à bénéficier des nouvelles technologies de pointe.",
        },
        "person-4": {
          name: "Aurélie",
          role: "Head of administration",
          bio: "Aurélie Aparicio accompagne les professionnels dans leurs démarches technico-administratives de conformité, en apportant sécurité et toute l’aide aux citoyens voulant passer par la bureautique de l'énergie solaire..",
        },
        "person-5": {
          name: "Kelly",
          role: "Head of Design & Junior Developer",
          bio: "Aurélie Aparicio accompagne les professionnels dans leurs démarches technico-administratives de conformité, en apportant sécurité et toute l’aide aux citoyens voulant passer par la bureautique de l'énergie solaire..",
        },
        "person-6": {
          name: "Sebastian",
          role: "Front-end Engineer",
          bio: "Aurélie Aparicio accompagne les professionnels dans leurs démarches technico-administratives de conformité, en apportant sécurité et toute l’aide aux citoyens voulant passer par la bureautique de l'énergie solaire..",
        },
      },
    },
    career: {
      title: "Projetsolaire is growing. Grow with us.",
      description:
        "We are based in Marseille and looking for people to join our passionate team. Interested in working in renewable energy and helping people go solar? Join us now",
      openings: {
        title: "Current open positions",
        "fullstack-engineer": "Senior fullstack engineer",
        "junior-frontend-engineer": "Junior frontend engineer",
        "junior-account-representative": "Junior account representative",
        "account-representative": "Senior account representative",
        "job-openings": "Job openings",
        role: "Role",
        description: "Description",
        salary: "Salary",
        location: "Location",
        "view-all-openings": "View all openings",
      },
      process: {
        "step-1": {
          title: "Apply",
          description:
            "Duis aute irure dolor in reprehenderitin volupta velit esse cillum sint occaecat cupidatat.",
        },
        "step-2": {
          title: "Screening",
          description:
            "Duis aute irure dolor in reprehenderitin volupta velit esse cillum sint occaecat cupidatat.",
        },
        "step-3": {
          title: "Interview",
          description:
            "Duis aute irure dolor in reprehenderitin volupta velit esse cillum sint occaecat cupidatat.",
        },
        "step-4": {
          title: "Job Offer",
          description:
            "Duis aute irure dolor in reprehenderitin volupta velit esse cillum sint occaecat cupidatat.",
        },
      },
      cta: {
        text: "Sounds like we are building something cool?",
        button: "Join us today",
      },

      apply: {
        title: "Apply for position",
        subtitle:
          "Tell us about you ! Complete the form below to submit your application.",
      },
    },
  },

  "dynamic-forms": {
    leads: {
      heading: {
        title: "Bonjour {fullName}!",
        subtitle:
          "Pour nous aider à modéliser le potentiel solaire de votre maison, nous avons besoin de quelques informations. Cela ne vous prendra que quelques minutes !",
      },
      "verifying-token": "Vérification de votre accès",
      submitted: "Vous avez partagez vos informations avec succès !",
      steps: {
        "customer-information": {
          title: "Vos informations personnelles",
          subtitle: "Confirmer vos informations personnelles",
        },
        address: {
          title: "Votre adresse",
          subtitle: "Confirmer votre adresse",
        },
        "electricity-bill": {
          title: "Votre facture d électricité",
          subtitle: "Télécharger votre facture d'électricité",
          "paragraph-1":
            "Pour pouvoir modéliser votre maison avec précision, nous avons besoin de votre facture d'électricité. Cela nous aide à projeter vos rendements avec votre contrat d'électricité réel.",
        },
        "enedis-mandate": {
          title: "Vos données de consommation",
          subtitle:
            "Donnez nous l'autorisation de demander vos données de consommation à Enedis",
          "paragraph-1":
            "En cliquant sur le bouton «  Valider », le Mandant – l’internaute et utilisateur du présent site internet  –  autorise expressément le Mandataire  –  la société élance Technologies Propres S.A.S, domiciliée 31 Avenue de Ségur, 75007, Paris, immatriculée au registre de commerce de Paris sous le numéro 878 632 306 00014, au capital social de 10,000 €",
          "paragraph-2":
            "à demander et à recevoir communication auprès du gestionnaire du réseau électrique Enedis – SA à directoire et à conseil de surveillance, au capital de 270 037 000 euros, immatriculée au R.C.S. de Nanterre sous le numéro 444 608 442 et dont le siège social est situé Tour Enedis, 34 Place des Corolles, 92070 Paris La Défense Cedex –",
          "paragraph-3":
            "des données cochées ci-dessous, sous réserve de disponibilité :",
          "paragraph-4":
            "Usage des données : Réalisation de l’étude technico-financière pour l’installation d’un système photovoltaïque en autoconsommation électrique. Le Client accepte expressément que ses données personnelles soient conservées par le Mandataire à des fins de gestion et de traçabilité. La présente autorisation ne peut être cédée. Elle est consentie pour une durée indéterminée. Conformément à la loi Informatique et Libertés du 6 janvier 1978, le Client dispose d’un droit d’accès, de rectification, de suppression et d’opposition pour motifs légitimes sur l’ensemble des données le concernant qu’il peut exercer sur simple demande auprès du Mandataire.",
          "checkbox-1": {
            title:
              "L’historique des mesures, en kWh, du site (et puissances atteintes et dépassements de puissance);",
            description:
              "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
          },
          "checkbox-2": {
            title:
              "L’historique des relevés d’index quotidiens, en kWh, du site;",
            description:
              "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
          },
          "checkbox-3": {
            title:
              "L’historique la puissance maximale quotidienne, en kVA ou kWh, du site ;",
            description:
              "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
          },
          "checkbox-4": {
            title:
              "L’historique de courbe de charge, aux pas restitués par Enedis, du site ;",
            description:
              "sur la période souhaitée, de 24 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
          },
          "checkbox-5": {
            title:
              "Les données techniques et contractuelles disponibles du site;",
            description:
              " Caractéristiques du raccordement, du dispositif de comptage et des informations contractuelles (option tarifaire, puissance souscrite…)",
          },
        },
        confirm: {
          title: "Envoyez vos informations",
          subtitle:
            "Une fois que vous êtes satisfait, soumettez vos informations pour votre modèle personnalisé",
          "paragraph-1":
            "Si vous êtes satisfait de vos informations, cliquez sur 'Envoyer' et nous commencerons à préparer votre modèle personnalisé dans les 48 heures.</br>",
        },
      },

      errors: {
        "token-invalid": "L'accès est invalide",
        "token-missing": "L'accès est invalide",
      },
    },
    administration: {
      heading: {
        title: "Bonjour {fullName}!",
        subtitle:
          "Pour compléter vos services, nous avons besoin de quelques informations. Cela ne vous prendra que quelques minutes à remplir !",
        "subtitle-for-installer":
          "Pour compléter les services de {customerName} au {fullAddress}, nous avons besoin des informations suivantes. Cela ne vous prendra que qu'une seule minute à remplir !",
      },

      submitted: "Vous avez partagez vos informations avec succès !",
      "verifying-token": "Vérification de votre accès",
      steps: {
        "customer-information": {
          title: "Vos informations personnelles",
          subtitle: "Confirmer vos informations personnelles",
        },
        address: {
          title: "Votre adresse",
          subtitle: "Confirmer votre adresse",
        },
        documents: {
          title: "Documents",
          subtitle:
            "Télécharger le document demandé pour compéter votre services",
          "paragraph-1":
            "Pour pouvoir modéliser votre maison avec précision, nous avons besoin de votre facture d'électricité. Cela nous aide à projeter vos rendements avec votre contrat d'électricité réel.",
          "property-deed": "titre de propriété",
          "mayor-approval": "avis de non-opposition",
          "electricity-bill": "facture électrique",
          "consuel-approval": "AC du Consuel",
          "manual-mayor-single-mandate":
            "Signature du mandat spécial de représentation - Autorisation d’urbanisme",
          "manual-enedis-single-mandate":
            "Signature du mandat spécial de représentation - Demande de raccordement",
          "wiring-diagram": "Schéma électrique unifilaire",

          esign: {
            "mayor-single-mandate":
              "Signature du mandat spécial de représentation - Autorisation d’urbanisme",
            "enedis-single-mandate":
              "Signature du mandat spécial de représentation - Demande de raccordement",
            "dual-mandate": "Signature du mandat spécial de représentation",
          },
        },
        hardware: {
          title: "Matériel",
          subtitle: "Ajouter l'inventaire avec lequel le système est installé",
          panelLayoutDescription: "Description du calepinage",
          panel: "Panneaux",
          inverter: "Onduleur",
        },
        technicals: {
          title: "Technicals",
          subtitle: "Ajouter les informations techniques de l'installation",
          "paragraph-1":
            "Si vous êtes satisfait de vos informations, cliquez sur 'Envoyer' et nous commencerons à préparer votre modèle personnalisé dans les 48 heures.</br>",
          "system-size": "puissance de l’installation solaire (kVa)",
          "usage-point": "numéro du Point De Livraison (PDL)",
          "grid-connection-types": "monophasé ou triphasé ?",
          "monophase-grid-connection": "monophasé",
          "triphase-grid-connection": "triphasé",

          "meter-container-sizes": "largeur du coffret",
        },
        confirm: {
          title: "Envoyez vos informations",
          "paragraph-1":
            "Confirmez que vous êtes d'accord pour soumettre toutes les informations saisies. Nous commencerons alors à compléter vos services pour vous.</br>",
        },
      },

      errors: {
        "token-invalid": "L'accès est invalide",
        "token-missing": "L'accès est invalide",
      },
    },
  },

  support: {
    home: {
      title: "Get in touch with our team",
      subtitle:
        "Select a category to email the support team or contact us directly by using the form below.",
      "get-in-touch": "Prendre contact",
      "support-card": {
        "start-solar-autonomy": {
          name: "Démarrer autonomie solaire",
          description:
            "Envie de bénéficier d'un conseil pro bono ? Prenez contact avec notre conseil indépendant pour particuliers.",
        },
        "for-industry-farmers": {
          name: "Pour industriels & agriculteurs",
          description:
            "Vous portez un projet solaire de grande puissance ? Prenez contact avec notre équipe au service des installateurs photovoltaïques.",
        },
        "for-photovoltaic-professionals": {
          name: "Pour artisans photovoltaïques",
          description:
            "Besoin de précisions sur nos solutions informatiques ? Prenez contact avec notre équipe technico-administrative pour professionnels.",
        },
        "technical-support": {
          name: "Support technique",
          description:
            "Besoin d'aide sur dépannage technique sur votre compte ? Prenez contact avec notre équipe informatique.",
        },
        "solar-partnerships": {
          name: "Partenariats solaires",
          description:
            "Vous recherchez des solutions informatiques pour le domaine photovoltaïque ? Prenez contact pour des opportunitées de partenariat.",
        },
        press: {
          name: "Presse",
          description:
            "Besoin de nous joindre ? Prenez contact avec notre relation presse.",
        },
      },
    },
    "our-office": {
      title: "Come visit us at our office!",
      subtitle:
        "Varius facilisi mauris sed sit. Non sed et duis dui leo, vulputate id malesuada non. Cras aliquet purus dui laoreet diam sed lacus, fames.",
    },
    "help-center": {
      title: "Explore our help centre for more information",
      subtitle:
        "We have alot of guides that may be useful in helping resolving your question",
    },
    schedule: {
      "chat-online": "Vous préférez discuter directement avec nous en ligne ?",
      "open-chatbox": "Ouvrir messagerie.",
      residential: {
        heading: "Pour particuliers",
        description:
          "Envie de bénéficier d'un conseil pro bono ? Prenez contact avec notre conseil indépendant pour particuliers.",
        breadcrumb: {
          name: "Démarrer son autonomie solaire",
        },
      },
      business: {
        heading: "Pour industriels & agriculteurs",
        description:
          "Vous portez un projet solaire de grande puissance ? Prenez contact avec notre équipe au service des installateurs photovoltaïques.",
        breadcrumb: {
          name: "Pour industriels & agriculteurs",
        },
      },
      installer: {
        heading: "Pour installateurs photovoltaïques",
        description:
          "Besoin de précisions sur nos solutions informatiques ? Prenez contact avec notre équipe technico-administrative pour professionnels.",
        breadcrumb: {
          name: "Pour installateurs photovoltaïques",
        },
      },
      "technical-support": {
        heading: "Support technique",
        description:
          "Besoin d'aide sur dépannage technique sur votre compte ? Prenez contact avec notre équipe informatique.",
        breadcrumb: {
          name: "Support technique",
        },
      },
      partnerships: {
        heading: "Partenariats solaires",
        description:
          "Vous recherchez des solutions informatiques pour le domaine photovoltaïque ? Prenez contact pour des opportunitées de partenariat.",
        breadcrumb: {
          name: "Partenariats solaires",
        },
      },
      press: {
        heading: "Presse",
        description:
          "Besoin de nous joindre ? Prenez contact avec notre relation presse.",
        breadcrumb: {
          name: "Presse",
        },
      },
    },
  },
  help: {
    header: {
      title: "Help Centre",
    },
    home: {
      search: {
        title: "Hi! How can we help?",
        subtitle:
          "Start building for free, then add a site plan to go live. Account plans unlock additional features.",
        placeholder: "Search guides",
      },
      householder: "I am a household",
      installer: "I am an installer",
    },
    householder: {
      title: "Tous les sujets",
      subtitle: "Parcourez notre bibliothèque complète de rubriques d'aide.",
      guides: {
        "your-internet-portal": {
          title: "Votre portail internet",
          description:
            "Instructions faciles à suivre pour créer un compte et gérer son espace en ligne.",
        },
        "payments-pricing-and-refunds": {
          title: "Paiements, tarification et remboursements",
          description:
            "Marche à suivre pour percevoir pleinement son gain économique, comparer les prix euro-watts-crête, régler l’acompte et le solde, et télécharger les factures et reçus.",
        },
        "choose-your-solar-system": {
          title: "Choisir son système solaire",
          description:
            "Comment bénéficier d’une étude de votre pourcentage d’autonomie électrique, et donc de vos économies, par rapport à différentes alternatives de votre projet solaire.",
        },
        "choosing-a-customised-quote": {
          title: "Choisir son devis sur-mesure",
          description:
            "Comment sélectionner parmi plusieurs propositions complètes, détaillés et sur-mesure d’installateurs photovoltaïques proches de chez vous.",
        },
        "validate-feasibility": {
          title: "Valider la faisabilité",
          description:
            "Comment planifier puis se préparer pour la rencontre avec le technicien, et se faire réaliser la faisabilité technique  chez vous du cahier des charges.",
        },
        "investing-in-solar": {
          title: "Investir dans le solaire",
          description:
            "Comment contrôler les assurances et certifications du maitre d’œuvre, signer électroniquement son devis définitif, et régler l’acompte en toute sécurité.",
        },
        "carrying-out-administrative-procedures": {
          title: "Réalisation des démarches administratives",
          description:
            "Comment suivre l’obtention de l’autorisation de l’urbanisme auprès de la mairie, puis planifier l’installation photovoltaïque.",
        },
        "installation-and-commissioning": {
          title: "Pose & mise en service",
          description:
            "Comment valoriser l’injection du surplus avec un raccordement au réseau électrique respectant les normes en vigueur.",
        },
        "legal-notices": {
          title: "Mentions juridiques",
          description:
            "Informations sur la relation contractuelle entre nous et le professionnel photovoltaïque, avec aperçu des conditions générales.",
        },
      },
      cta: {
        title: "Discover others solar journey",
        subtitle:
          "Quasi est quaerat. Sit molestiae et. Provident ad dolorem occaecati eos iste. Soluta rerum quidem minus ut molestiae velit error quod. Excepturi quidem expedita molestias quas.",
        description:
          "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat. Quasi aperiam sit non sit neque reprehenderit.",
        button: "Join our team",
      },
    },
    installer: {
      title: "Tous les sujets",
      subtitle: "Tous les sujets",
      guides: {
        "your-online-account": {
          title: "Votre compte en ligne",
          description:
            "Instructions faciles à suivre pour créer un compte et gérer son espace professionnel.",
        },
        "your-solar-projects": {
          title: "Vos projets solaires",
          description:
            "Instructions simples à suivre pour la gestion et le suivi de prospects, devis, dossiers puis chantiers photovoltaïques sur une seule interface en ligne.",
        },
        "online-photovoltaic-studies": {
          title: "Études photovoltaïques en ligne",
          description:
            "Tout savoir sur comment mieux vendre avec nos présentations personnalisables, vos propositions sur-mesure de systèmes solaires, et notre méthode technico-financière.",
        },
        "dematerialised-administrative-management": {
          title: "Gestion administrative automatisée",
          description:
            "Tout savoir sur comment gagner du temps avec nos services numériques d’envoi et de suivi des démarches administratives obligatoires pour installations photovoltaïques.",
        },
        "contact-and-quotation-proposals": {
          title: "Discover new clients",
          description:
            "Tout savoir sur les signatures électroniques, les paiements automatiques sur internet, la mise en conformité, le service d’assistance de maîtrise d’ouvrage, et bien plus encore. ",
        },
        "materials-planning": {
          title: "Planification du matériel",
          description:
            "Tout savoir sur les listes de matériel de l’entreprise, kits personnalisables, approvisionnement et gestion du stock, comptes rendus de chantiers, et bien plus encore.",
        },
        "after-sales-service": {
          title: "Service après-installation",
          description:
            "Tout savoir sur la responsabilité de chacun, communication entre le maitre d’ouvrage et le maitre d’œuvre, l’entretien, le suivi de rendements, le dépannage et le nettoyage.",
        },
        "payments-pricing-and-refunds": {
          title: "Paiements, tarification et remboursements",
          description:
            "Marche à suivre pour percevoir de l’argent, gagner du chiffre d’affaires, acheter au travers de la boutique en ligne, et télécharger les factures comptables.",
        },
        "legal-notices": {
          title: "Mentions juridiques",
          description:
            "Informations sur la relation contractuelle entre nous et le professionnel photovoltaïque, avec aperçu des conditions générales mentions légales.",
        },
      },
      cta: {
        title: "Join our platform",
        description:
          "Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in accusamus quisquam.",
        benefit: {
          benefit1: "Competitive salaries",
          benefit2: "Flexible work hours",
          benefit3: "30 days of paid vacation",
          benefit4: "Annual team retreats",
          benefit5: "Benefits for you and your family",
          benefit6: "A great work environment",
        },
        button: "Sign up now",
      },
    },
    "side-bar": {
      "bread-crumb": {
        help: "Help",
      },
      search: "Search",
      "for-anything": "for anything",
      placeholder: "...",
    },
    
  },

  common: {
    loading: "Loading...",
    processing: "Chargement...",
    warning: "Alerte !",
    copyright: "2023 © projetsolaire.com. All rights reserved.",
    name: "Name",
    "enter-your-name": "Enter your name",
    email: "Email",
    "enter-your-email": "Enter your email address",
    "your-email": "Your email",
    password: "Password",
    "enter-your-password": "Enter your password",
    siret: "Votre numéro SIRET",
    "enter-your-siret": "Entrez votre SIRET",
    "phone-number": "Votre numéro de téléphone",
    "enter-your-phone-number": "Entrez votre numéro de téléphone",
    "sign-up": "Sign Up",
    "sign-in": "Sign in",
    or: "Ou",
    apply: "Apply",
    optional: "Optional",
    "select-a-tab": "Select a tab",
    title: {
      mr: "Mr",
      mrs: "Mrs",
    },
    "not-signed-in": {
      title: "Not logged in",
      description: "Sorry, to access this content you need to be logged in",
      button: "Sign in now",
    },
    "cookie-gdpr": {
      "cookie-text-1":
        "Nous utilisons les cookies pour mettre nos services à votre disposition.",
      "cookie-text-2": "Pour en savoir, veuillez explorer notre ",
      "cookie-link": "Déclaration de Confidentialité",
      accept: "Accepter",
      decline: "Refuser",
    },
    "algolia-search-dialog": {
      search: "Search",
      placeholder: "Search Anything...",
      results: "Results",
      tags: {
        installer: "Installer",
        householder: "Householder",
        "help-center": "Help center",
        blog: "Blog",
      },
    },
    verifying: {
      title: "Verifying",
      title2: "Verifying that its you",
      description: "We are verifying your access, please bear with us",
    },
    error: {
      title: "Oops there was a problem",
      button: "Go back home",
    },
    success: {
      "confirmed-account": "Successfully confirmed your account",
      "sign-in": "Sign in now",
      "reset-password": "Successfully reset password",
      continue: "Continue",
    },
    "page-not-found": {
      title: "This page does not exist.",
      description: "The page you are looking for could not be found.",
      "go-home": "Go back home",
    },
    "newsletter-dialog": {
      title: "Signup to our newsletter",
      description:
        "We send out some new content each month updating you on the latest news with our mission",
      form: {
        "first-name": "First name",
        "last-name": "Last name",
        company: "Company",
        email: "Email",
      },
      "agree-to-policies": "Agree to policies",
      "switch-agree": "By selecting this, you agree to our",
      "privacy-policy": "privacy policy",
      subscribe: "Subscribe",
    },
    month: {
      january: "January",
      february: "February",
      march: "March",
      april: "April",
      may: "May",
      june: "June",
      july: "July",
      august: "August",
      september: "September",
      october: "October",
      november: "November",
      december: "December",
    },
    pagination: {
      showing: "Affiche",
      to: "à",
      of: "de",
      results: "résultats",
      previous: "Précédent",
      next: "Suivant",
      related: "Related",
    },
  },

  snackbars: {
    "lead-submitted": "Nous avons reçu votre demande",
    "password-reset-success": "votre mot de passe a été mis à jour.",
    "reset-token-sent":
      "Vérifiez votre boîte mail pour mettre à jour votre mot de passe.",
    "register-success":
      "Vous avez créé votre compte projetsolaire ! Confirmez votre inscription avec le courriel dans votre boîte mail.",
    "login-success": "Bienvenue sur projetsolaire",
    errors: {
      common:
        "Une erreur s’est produite. Veuillez réessayer puis nous contacter si le problème persiste.",
      "password-reset-failed": "La mise à jour du mot de passe a échouée.",
      "login-failed":
        "Connexion échouée : vérifier votre saisie puis réessayer.",
      "register-failed":
        "Nous n’avons pas pu créer votre compte. Veuillez réessayer puis nous contacter si le problème persiste.",
    },
  },
}
