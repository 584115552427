export default {
  hero: {
    "find-an-installer": "Trouver un installateur",
    "read-our-blog": "Je suis un poseur",
    title: "Gagnez vite en autonomie solaire",
    subtitle:
      "Votre moyen le plus rapide pour réaliser votre projet sur-mesure. Une seule facture élec suffit. Trouvez votre meilleur artisan photovoltaïque RGE proche de chez vous. Accédez enfin à l'énergie solaire.",
    "subtitle-min":
      "Votre moyen le plus rapide pour réaliser votre projet sur-mesure.",
    "learn-more": "En savoir plus",
    "discover-potential": "Je découvre mon potentiel solaire",
    "watch-video": "Voir la vidéo (30 secondes)",
  },
  "lead-flow": {
    address: {
      title: "Où se situe votre gisement solaire ?",
      subtitle: "Entrez votre addresse ci-dessous",
      buildingNotFoundDescription:
        "Votre batîment n'est pas celle indiqué par l'épingle ? Faites-la glisser sur le point exact.",
    },
    "electricity-bill": {
      title: "Votre facture d'électricité",
      description:
        "Cet espace est dédié à l’ajout de votre facture d’électricité récente. Elle nous permet de vérifier votre identité, d'établir votre type de contrat d'électricité, et de créer une prévision de votre nouvelle facture d'électricité en passant au solaire. Ces données sont personnelles et protégées ; elles ne sont utilisées sur notre plateforme que dans le cadre de votre projet solaire.",
      terms: "Pourquoi nous vous demandons votre facture d'électricité ?",
      tooltip: {
        title: "Veillez à ce que ces informations soient présentes :",
        requiredFields: {
          price: "Prix du kWh",
          size: "Puissance (kVa)",
          type: "Type de contrat (base/heure pleines heures creuses)",
          pdl: "Numéro PDL",
        },
      },
    },
    "enedis-mandate": {
      linky: {
        title: "Êtes-vous équipé d'un compteur Linky ?",
        description:
          "Si vous possédez un compteur Linky, nous pouvons procéder à l’analyse horaire de votre historique de consommation en kWh. Cela nous permettra de calculer votre potentiel d’autoconsommation électrique heure par heure, et vous réaliser ainsi une étude techniquement précise.",
        "no-linky": {
          title:
            "Sans Linky nous ne sommes pas en mesure de vous réaliser une étude technico-financière à partir de mesures horaires. Vous pouvez demander le remplacement gratuit de votre compteur actuel par un compteur Linky en",
          link: "",
        },
      },
      title: "Autorisation Linky",
      subtitle:
        "Afin de pouvoir étudier votre historique de consommation d'électricité depuis votre compteur Linky, votre accord est nécessaire pour accéder vos données personnelles, en tant qu’entité référencée auprès d’Enedis. Vos données sont protégées et ne seront utilisées et conservées qu'à cette fin.",
      form: {
        required: {
          enedisHistoricMeasurementsAccessGranted:
            "L'historique des mesures est requis.",
          enedisHistoricDailyConsumptionDataAccessGranted:
            "L'historique des relevés est requis.",
          enedisHistoricPowerDataAccessGranted:
            "L'historique de la puissance est requis.",
          enedisHistoricConsumptionLoadCurveDataAccessGranted:
            "L'historique de courbe est requis.",
          enedisContractAccessGranted:
            "Les données techniques et contractuelles sont requises.",
        },
      },
    },
    informations: {
      title: "Dernière étape",
      subtitle: "Pour obtenir votre étude gratuite.",
      firstname: "Prénom",
      lastname: "Nom",
      "company-name": "Entreprise",
      "company-siret": "Siret",
      phone: "Téléphone",
      password: "Mot de passe",
      "confirm-password": "Confirmez votre mot de passe",
      "see-stories": "Voir les autres histoires",
    },
    type: {
      title: "Vous êtes %{type}",
      RESIDENTIAL: "Un particulier",
      BUSINESS: "Une entreprise",
    },
    "project-type": {
      title: "Quel sera votre projet solaire sur-mesure ?",
      carport: "Carport",
      pergola: "Pergola",
      "ground-mount": "Au sol",
      tiles: "Sur toiture",
      "flat-roof": "Toiture terrasse",
      "solar-tiles": "Tuiles solaires",
    },
    "registration-success": {
      title: "Un email de confirmation vous a été envoyé",
      subtitle:
        "Votre étude est en cours de préparation ! Pour suivre son avancement consultez votre profil",
      question:
        "Avous-vous des questions ? Ou souhaitez-vous parler de votre projet avec une personne de notre équipe ?",
      calendly: "Prennez rendez-vous avec l'un de nos collaborateurs !",
    },
    location: "Emplacement",
    profile: "Profil",
    projectType: "Type de projet",
    electricBill: "Facture électrique",
    consumptionData: "Donnée de conso",
  },
  "how-to": {
    title: "Comment accéder à l'autonomie solaire ?",
    "description-2":
      "Nous développons une plateforme communautaire clé-en-main de mise en lien entre tout porteur de projet solaire et les professionnels du photovoltaïque et du stockage.",
    "description-1":
      "La flambée des prix est hors de contrôle. Notre objectif est de faciliter, sécuriser puis démocratiser à grande échelle toutes les étapes de l’installation photovoltaïque.",
  },

  "why-us": {
    title: "Pourquoi choisir projetsolaire ?",
    "description-1":
      "Nous encadrons la réalisation de votre installation photovoltaïque par un maitre d'œuvre RGE : transparence des offres des poseurs, sans engagement, à votre rythme.",
    "description-2": "Le service est gratuit pour les particuliers.",
    features: {
      //unused
      model: {
        title: "Étude technico-financière à distance",
        description:
          "Notre étude photovoltaïque détermine le nombre optimal de panneaux solaires chez vous, ainsi que leurs rendements, afin de définir votre cahier des charges indépendamment des installateurs photovoltaïques.",
      },
      installers: {
        title: "Mise en service avec les artisans photovoltaïques",
        description:
          "Notre appel d’offres en ligne vous permet de recevoir plusieurs devis détaillés et sur-mesure d’installateurs de proximité, tout en restant anonyme.",
      },
      administration: {
        title: "Démarches dématérialisées",
        description:
          "Nos services numériques incluent toutes les démarches administratives : autorisations d’urbanisme, demandes de raccordement, sécurité électrique, commercialisation du surplus, bureaux de contrôle.",
      },
      support: {
        title: "Accompagnement clé-en-main",
        description:
          "Besoin de financement par prêt bancaire ? Questions sur les assurances et garanties ? Nous prenons le temps avec les acteurs votre projet solaire.  Nous sécurisons les travaux du cahier de charges jusqu’au recyclage des panneaux.",
      },
    },
    "start-with-this-installer": "Commencer avec cet installateur",
  },
  stats: {
    "our-track-record": "Nos résultats",
    title: "Première plateforme en ligne d'appels d'offres du solaire",

    description:
      "Recevez et comparez plusieurs devis détaillés, tout en restant anonyme. Sélectionnez votre meilleur installateur photovoltaïque à un prix compétitif.",

    "stats-value": {
      "aided-solar-projects": {
        title:
          "Projets solaires rendus possibles sur notre plateforme communautaire",
        value: "3 000 +",
      },
      "installers-in-network": {
        title: "Installateurs photovoltaïques de proximité, tous certifiés RGE",
        value: "100 +",
      },
      "3-quotes-guarantee": {
        title: "Entreprises par appel d'offres, moyenne",
        value: "4 propositions",
      },
      "average-time-install": {
        title: "Durée moyenne d'installation",
        value: "3 mois",
      },
      "cost-reduction": {
        title: "Réduction sur montant du devis, moyenne",
        value: "40 %",
      },
    },
  },
  "logo-cloud": {
    title: "Ils nous ont fait confiance",
  },
  stories: {
    title: "Rencontrer votre artisan parmi des centaines d’entreprises locales",
    description:
      "Pour la première fois depuis la révolution industrielle, les foyers et communautés peuvent produire leurs propres énergies à domicile. Nous voulons donc redéfinir la relation des foyers à l’énergie en valorisant la proximité, le partage et le progrès.",
    button: "Découvrez leurs témoignages",
  },
  community: {
    title: "Rejoignez notre mouvement citoyen",
    description:
      "Tous les jours nous aidons des familles à découvrir leurs potentiels solaires. Nous avons déjà aidé des centaines de foyers à accéder au solaire dans toute la France. Commencez votre passage à l’autonomie solaire aujourd’hui et rejoignez le mouvement national.",
  },
  mission: {
    title: "La promesse projetsolaire",
    subtitle: "L'autonomie solaire à portée de main",
    paragraph1:
      "Votre énergie est une dépendance polluante. Nous facilitons le passage au solaire grâce à l'informatique au service du photovoltaïque.",
    paragraph2: "Réduire le prix du solaire",
    paragraph3: "Mettre fin à l’éco-délinquance",
    paragraph4: "Accélérer la transition énergétique",
    paragraph5:
      "Projetsolaire.com c’est donc le projet solaire d’une famille, d’une PME familiale, d’une commune… c’est son projet solaire, c’est leur projet solaire. C’est enfin votre projet solaire aussi.",
  },
  cta: {
    title: "Nous orchestrons les acteurs de votre projet solaire",
    subtitle:
      "Bénéficiez d’un accompagnement clé en main pour une installation photovoltaïque meilleure, plus simple, et moins chere.",
    benefit: {
      benefit1: "Visite technique obligatoire",
      benefit2: "Autorisation de l'urbanisme",
      benefit3: "Contrôle sécurité électrique",
      benefit4: "Mise en service avec Enedis",
      benefit5: "Coffre-fort numérique",
      benefit6: "Suivi en temps réel de production",
    },
    button: "Explorer",
  },
  "learn-centre-cta": {
    title: "Tout savoir sur",
    description: "Consultez nos articles pour devenir un pro du solaire.",
  },
}
