import ambassadors from "./ambassadors"
import signUp from "./sign-up"
import signIn from "./sign-in"
import home from "./home"
import installer from "./installer"
import blog from "./blog"
import common from "./common"
import guides from "./guides"
import dynamicForms from "./dynamic-forms"
import metaTags from "./meta-tags"

export default {
  ambassadors,
  guides,
  "meta-tags": metaTags,
  navigation: {
    blog: "Ressources",
    installers: "professionnels",
    installersDropdown: "Professionnels",
    help: "Centre d'aide",
    "about-us": "Nous connaître",
    "sign-in": "Connexion",
    "sign-up": "Inscription",
    documentation: "Documentation",
    installer: "Professionnels",
    "digital-sales": "Études",
    administration: "Gestion administrative",
    "find-new-clients": "Mise en relation",
    sizing: "Dimensionnement",
  },

  footer: {
    "products-for-installers": "Solutions pour pros",
    "find-clients": "Nouveaux clients",
    administration: "Gestion administrative",
    "digital-sales-tools": "Études en ligne",
    "resale-agreements": "Contrats d'achat",
    resources: "Ressources",
    blog: "Tous les articles",
    documentation: "Documentation pro",
    guide: "Guide solaire",
    "support-centre": "Support client",
    partners: "Partenariats",
    enterprise: "Entreprise",
    home: "Notre activité",
    "about-us": "Nous connaître",
    "company-values": "Notre engagement",
    pricing: "Tarification",
    newsletter: "Bulletin de projetsolaire",
    "subscribe-newsletter":
      "Actualités, mises à jour, exclusivités : soyez au premier rang.",
    "your-email": "Votre email",
    copyright: `${new Date().getFullYear()} © projetsolaire. Tous droits réservés.`,
    "terms-and-conditions": "Mentions légales",
    "privacy-policy": "Déclaration de Confidentialité",
    "contact-us": "Contact",
    press: "Presse",
    technology: "Technologie",
    careers: "Carrières",
    ambassadors: "Ambassadeurs",
  },

  "reset-password": {
    title: "Récupérer son mot de passe",
    subtitle:
      "Entrez l'email que vous avez utilisée lors de votre inscription, et nous vous enverrons un email automatique de réinitialisation du mot de passe.",
    "check-your-inbox": "vérifiez votre boîte de réception",
    type: {
      householder: "Propriétaire",
      installer: "Professionnel",
    },
    success: {
      title: "Vérifiez votre boîte email de réception.",
      description:
        "Si vous n'avez pas reçu d'email, veuillez vérifier votre courriel indésirable.",
    },
    callback: {
      title: "Réinitialiser votre mot de passe",
      "description-1":
        "« Pour protéger vos informations, il est nécessaire de choisir et d’utiliser des mots de passe robustes, c’est-à-dire difficiles à retrouver à l’aide d’outils automatisés et à deviner par une tierce personne »",
      "description-2": "www.interieur.gouv.fr",
      anssi: "l’Agence Nationale de la Sécurité des Systèmes d’Information",
      password: "Mot de passe",
      "confirm-password": "Confirmation du mot de passe",
      "reset-your-password": "Réinitialiser votre mot de passe",
      errors: {
        "no-token-present": "Le lien de réinitialisation est invalide",
        "token-expired": "Le lien de réinitialisation a expiré",
        "token-invalid": "Le lien de réinitialisation est invalide",
      },
      "resend-link":
        "Renvoyer un lien de réinitialisation de mot de passe par email",
    },
    "forgot-password": {
      title: "Mot de passe oublié ?",
      description:
        "Entrez l'e-mail que vous avez utilisée lors de votre inscription de votre compte, et nous vous enverrons l'email de réinitialisation sécurisée du mot de passe.",
      "select-type": "Vous êtes ...",
      email: "Email",
      "reset-your-password": "Réinitialiser votre mot de passe",
    },
  },

  "sign-in": signIn,
  "sign-up": signUp,

  home,
  installer,
  blog,

  about: {
    "mission-statement":
      "<span class='opacity-80'>Notre mission : </span>orchestrer l’essor de l’énergie solaire <span class='opacity-80'>pour nous tous.</span>",
    intro: {
      title: "Acteur de l’indépendance énergétique de France",
      subtitle: "Faire avancer la transition énergétique, ensemble",
      description:
        "Dès le début, notre mission a été d’orchestrer l’essor de l’énergie solaire à grande échelle pour nous tous. Nous réalisons le projet solaire de France en aidant chaque projet solaire individuel à se réaliser en toute confiance, même pour les particuliers. Nous sommes fiers de pouvoir aider. Car si chaque citoyen réalise l’indépendance énergétique chez soi, alors la France l’aura également réalisé.",
    },
    story: {
      title: "Notre parcours",
      "paragraph-1":
        "Notre technologie a pour vocation de réduire la fracture numérique qui se creuse dans notre pays.",
      "paragraph-2":
        "Notre outil informatique du quotidien aidera bientôt des milliers d’artisans photovoltaïques de proximité à croître leurs activités. Sachez qu’ils forment un tissu économique fragile et dorénavant essentiel à la vitalité des communes rurales. Les installations photovoltaïques génèrent déjà des revenus essentiels à la survie des exploitations agricoles, viticoles et apicoles.",
      "paragraph-3":
        "La filière photovoltaïque est la source d’un véritable gisement d’emplois au sein des territoires ruraux, source de travail technique de haut niveau donc bien payé. L’énergie solaire contribue ainsi à l’essor d’une économie locale, au cœur d’une transition énergétique régénératrice. Ce sont nos territoires appauvris qui retrouvent la prospérité.",
      "paragraph-4":
        "L’indépendance énergétique de la France est possible grâce aux technologies propres ; et nous y sommes à la tête car à la pointe.",
      "paragraph-5":
        "Nous nous sommes élancés dans le solaire dès qu’on a compris à quel point il est difficile à nos parents de devenir autosuffisant en énergie propre, malgré le formidable progrès des technologies photovoltaïques.",
      "paragraph-6":
        "Implantée en Provence, notre jeune entreprise innovante est composée de spécialistes de l’énergie solaire et d'informaticiens. À ce jour, nous n’avons jamais eu besoin de lever de l’argent ni de dépenser un seul euro en publicité. Au contraire, notre méthode c’est la satisfaction client et le bouche-à-oreille.",
      "paragraph-7":
        "Nos parcours à l’étranger, et formations autodidactes en génie électrique, nous permettent aujourd’hui de nous donner les moyens de nos ambitions.",
      "paragraph-8":
        "Notre ambition ? L'énergie solaire partout pour nous tous … et répondre ainsi aux idéaux de justice climatique de l’Accord de Paris.",
    },
    stats: {
      title: "Nous sommes des orchestrateurs de projets solaires",
      description:
        "Notre plateforme communautaire met en lien tous les porteurs de projets solaires avec tous les professionnels du photovoltaïque et du stockage ; ensemble avec tous les acteurs de la transition énergétique - bureaux d’études, fournisseurs, financiers, municipalités, services publics.",
      "flagship-projects": "Chantiers en ligne",
      "flagship-projects-stat": "100+ MW",
      "installers-on-our-platform": "Artisans photovoltaïques",
      "solar-projects-enabled": "Projets solaires propulsés",
      "followers-stat": "25 000+",
      followers: "Personnes nous suivent",
    },
    team: {
      title: "L’équipe derrière la technologie",
      description:
        "Nous sommes passionnées par ce que nous faisons et soucieux d'obtenir les meilleurs résultats pour nos clients.",
      people: {
        "person-1": {
          name: "Georges",
          role: "Responsable relations & cofondateur",
          bio: "Georges Séraphim-André Nevsky aide des milliers nos concitoyens à découvrir leur potentiel solaire. Il aide les artisans photovoltaïques à bénéficier des nouvelles technologies de pointe.",
        },
        "person-2": {
          name: "Yann",
          role: "Responsable opérations & cofondateur",
          bio: "Yann Vermeil Schremmer développe de nouveaux modes de fonctionnement en interne, puis coordonne les divers interlocuteurs externes pour allier entreprenariat et environnement.",
        },
        "person-3": {
          name: "Maarten",
          role: "Responsable technologie & cofondateur",
          bio: "Maarten Elgar développe la technologie qui aide les foyers à se mettre à l'énergie solaire en leur permettant d'obtenir des devis compétitifs, puis de réaliser le reste du parcours en ligne.",
        },
        "person-4": {
          name: "Kelly",
          role: "Développeuse",
          bio: "Kelly Muscat créé les visuels du site internet. Elle compte faire rayonner projetsolaire, qui lui tient a cœur pour sa noble mission : rendre le solaire plus accessible autant pour les professionnels que pour les particuliers !",
        },
        "person-5": {
          name: "Alice",

          role: "Coordinatrice de communauté",

          bio: "Alice Lucà contribue au développement de notre marque, optimise notre présence en ligne et participe à la croissance des communautés liées au photovoltaïque, pour une énergie solaire à portée de tous·tes.",
        },
        "person-6": {
          name: "Wakidou",
          role: "Développeur frontend",
          bio: "Wakidou Abdallah est développeur frontend. Il contribue au développement de la plateforme en créant des interfaces ergonomiques et performantes. Son objectif est de faciliter et d'accélérer le passage au solaire à travers le portail communautaire.",
        },
        "person-7": {
          name: "Louise",
          role: "Coordinatrice d'opérations de croissance",
          bio: "Louise Remy est coordinatrice des opérations de croissance. Elle elle lien entre les installateurs et nos équipes de développement et d’opérations. Son objectif est d’améliorer notre outil au maximum en vue de la pleine satisfaction de nos clients.",
        },
        "person-8": {
          name: "Nicolas",
          role: "Développeur backend",
          bio: "Nicolas Chamand s'occupe du développement de la partie non visible de la solution, notamment son API. Son objectif : optimiser les performances de l'application et améliorer la satisfaction utilisateur pour ainsi accélerer le développement du solaire.",
        },
      },
    },
    career: {
      title: "Notre projetsolaire grandit. Grandissez avec nous.",
      description:
        "Implantée en Provence, nous sommes à la recherche de talents à faire fleurir et prospérer. Envie d’avoir un impact sur le climat en aidant nos concitoyens à faire la revolution solaire ? Bienvenue !",
      openings: {
        title: "Postes actuellement ouverts",
        "fullstack-engineer": "Développeur fullstack",
        "junior-frontend-engineer": "Développeur frontend",
        "junior-account-representative": "Chargé de comptes",
        "account-representative": "Responsable grands comptes",
        "job-openings": "Offres d'emploi",
        role: "Role",
        description: "Description",
        salary: "Salaire",
        location: "Localisation",
        "view-all-openings": "Voir tous les postes vacants",
      },
      process: {
        "step-1": {
          title: "Postuler",
          description: "Partagez-nous votre expérience et savoir faire.",
        },
        "step-2": {
          title: "Vérification",
          description: "Votre candidature est évaluée par rapport aux besoins.",
        },
        "step-3": {
          title: "Entretien",
          description:
            "Vous prenez connaissance de l’équipe et de ces valeurs.",
        },
        "step-4": {
          title: "Proposition",
          description: "Nous nous engageons auprès de vous.",
        },
      },
      cta: {
        text: "Voulez-vous créer quelque chose de grand ?",
        button: "Rejoignez-nous aujourd’hui",
      },

      commitments: {
        FULL_TIME: "Temps plein",
        PART_TIME: "Temps partiel",
        INTERNSHIP: "Stage",
        CONTRACT: "Auto-entrepreneur",
        COFOUNDER: "Directeur⸱ice",
      },

      roles: {
        COFOUNDER: "Directeur⸱ice",
        PROGRAMMING: "Développeur⸱euse",
        CONTRACT: "Auto-entrepreneur",
        DESIGN: "Designeur⸱euse",
        MANAGEMENT: "Gérant⸱e",
        BUSINESS_DEVELOPMENT: "Développeur⸱euse d'affaires",
        SALES_AND_MARKETING: "Commercial⸱e",
        ADMINISTRATION: "Administratif",
      },

      apply: {
        title: "Nouvelle candidature",
        subtitle:
          "Parlez-nous de vous ! Complétez le formulaire ci-dessous pour soumettre votre candidature.",
      },
    },
  },

  "dynamic-forms": dynamicForms,

  support: {
    home: {
      title: "Prenez contact avec nos équipes",
      subtitle:
        "Sélectionnez votre besoin pour réserver un créneau en visio-conférence.",
      "get-in-touch": "Prendre contact",
      "support-card": {
        "start-solar-autonomy": {
          name: "Démarrer autonomie solaire",
          description:
            "Envie de bénéficier d'un conseil pro bono ? Prenez contact avec notre conseil indépendant pour particuliers.",
        },
        "for-industry-farmers": {
          name: "Pour industriels & agriculteurs",
          description:
            "Vous portez un projet solaire de grande puissance ? Prenez contact avec notre équipe technico-administrative.",
        },
        "for-photovoltaic-professionals": {
          name: "Pour artisans photovoltaïques",
          description:
            "Besoin de précisions sur nos solutions informatiques ? Prenez contact en tant que professionnel photovoltaïque.",
        },
        "technical-support": {
          name: "Accompagnement",
          description:
            "Besoin d'aide ou bien envie de découvrir toutes les fonctionnalités sur votre compte ? Prenez contact avec notre équipe technique.",
        },
        "solar-partnerships": {
          name: "Partenariats solaires",
          description:
            "Vous recherchez des opportunitées de partenariat entre l'informatique et le photovoltaïque ? Prenez contact !",
        },
        press: {
          name: "Presse",
          description:
            "Besoin de nous joindre ? Prenez contact avec notre relation presse pour mettre en valeur les technologies propres.",
        },
      },
    },
    "our-office": {
      title: "Venez-nous rendre visite !",
      subtitle:
        "Implantées au Vieux-Port de Marseille, nous avons opté pour un QG dans un ancien atelier d’art, à coté du Mémorial de la Marseillaise, afin de contribuer à la régénération du quartier.",
    },
    "help-center": {
      title: "Consultez notre centre d'aide pour plus d'informations",
      subtitle:
        "Nous disposons de nombreux guides qui peuvent vous aider à résoudre votre question.",
    },
    schedule: {
      "chat-online": "Vous préférez discuter directement avec nous en ligne ?",
      "open-chatbox": "Ouvrir messagerie",
      residential: {
        heading: "Pour particuliers",
        description:
          "Envie de bénéficier d'un conseil pro bono ? Prenez contact avec notre conseil indépendant pour particuliers.",
        breadcrumb: {
          name: "Démarrer son autonomie solaire",
        },
      },
      business: {
        heading: "Pour industriels & agriculteurs",
        description:
          "Vous portez un projet solaire de grande puissance ? Prenez contact avec notre équipe au service des installateurs photovoltaïques.",
        breadcrumb: {
          name: "Pour industriels & agriculteurs",
        },
      },
      installer: {
        heading: "Pour installateurs photovoltaïques",
        description:
          "Besoin de précisions sur nos solutions informatiques ? Prenez contact avec notre équipe technico-administrative pour professionnels.",
        breadcrumb: {
          name: "Pour installateurs photovoltaïques",
        },
      },
      "technical-support": {
        heading: "Accompagnement",
        description:
          "Besoin d'aide ou bien envie de découvrir toutes les fonctionnalités sur votre compte ? Prenez contact avec notre équipe technique.",
        breadcrumb: {
          name: "Accompagnement",
        },
      },
      partnerships: {
        heading: "Partenariats solaires",
        description:
          "Vous recherchez des solutions informatiques pour le domaine photovoltaïque ? Prenez contact pour des opportunitées de partenariat.",
        breadcrumb: {
          name: "Partenariats solaires",
        },
      },
      press: {
        heading: "Presse",
        description:
          "Besoin de nous joindre ? Prenez contact avec notre relation presse.",
        breadcrumb: {
          name: "Presse",
        },
      },
    },
  },
  help: {
    header: {
      title: "Centre d'aide",
    },
    home: {
      search: {
        title: "Bonjour ! Comment pouvons-nous vous aider ?",
        subtitle: "",
        placeholder: "Recherche dans les guides",
      },
      householder: "Je suis un propriétaire",
      installer: "Je suis un installateur",
    },
    householder: {
      title: "Tous les sujets",
      subtitle: "Parcourez notre bibliothèque complète de rubriques d'aide.",
      guides: {
        "your-internet-portal": {
          title: "Votre portail internet",
          description:
            "Instructions faciles à suivre pour créer un compte et gérer son espace en ligne.",
        },
        "payments-pricing-and-refunds": {
          title: "Paiements, tarification et remboursements",
          description:
            "Marche à suivre pour percevoir pleinement son gain économique, comparer les prix euro-watts-crête, régler l’acompte et le solde, et télécharger les factures et reçus.",
        },
        "choose-your-solar-system": {
          title: "Choisir son système solaire",
          description:
            "Comment bénéficier d’une étude de votre pourcentage d’autonomie électrique, et donc de vos économies, par rapport à différentes alternatives de votre projet solaire.",
        },
        "choosing-a-customised-quote": {
          title: "Choisir son devis sur-mesure",
          description:
            "Comment sélectionner parmi plusieurs propositions complètes, détaillés et sur-mesure d’installateurs photovoltaïques proches de chez vous.",
        },
        "validate-feasibility": {
          title: "Valider la faisabilité",
          description:
            "Comment planifier puis se préparer pour la rencontre avec le technicien, et se faire réaliser la faisabilité technique  chez vous du cahier des charges.",
        },
        "investing-in-solar": {
          title: "Investir dans le solaire",
          description:
            "Comment contrôler les assurances et certifications du maitre d’œuvre, signer électroniquement son devis définitif, et régler l’acompte en toute sécurité.",
        },
        "carrying-out-administrative-procedures": {
          title: "Réalisation des démarches administratives",
          description:
            "Comment suivre l’obtention de l’autorisation de l’urbanisme auprès de la mairie, puis planifier l’installation photovoltaïque.",
        },
        "installation-and-commissioning": {
          title: "Pose & mise en service",
          description:
            "Comment valoriser l’injection du surplus avec un raccordement au réseau électrique respectant les normes en vigueur.",
        },
        "legal-notices": {
          title: "Mentions juridiques",
          description:
            "Informations sur la relation contractuelle entre nous et le professionnel photovoltaïque, avec aperçu des conditions générales.",
        },
      },
      cta: {
        title: "Discover others solar journey",
        subtitle:
          "Quasi est quaerat. Sit molestiae et. Provident ad dolorem occaecati eos iste. Soluta rerum quidem minus ut molestiae velit error quod. Excepturi quidem expedita molestias quas.",
        description:
          "Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo. Elit sunt amet fugiat veniam occaecat fugiat. Quasi aperiam sit non sit neque reprehenderit.",
        button: "Join our team",
      },
    },
    installer: {
      title: "Tous les sujets",
      subtitle: "Tous les sujets",
      guides: {
        "your-online-account": {
          title: "Votre compte en ligne",
          description:
            "Instructions faciles à suivre pour créer un compte et gérer son espace professionnel.",
        },
        "your-solar-projects": {
          title: "Vos projets solaires",
          description:
            "Instructions simples à suivre pour la gestion et le suivi de prospects, devis, dossiers puis chantiers photovoltaïques sur une seule interface en ligne.",
        },
        "online-photovoltaic-studies": {
          title: "Études photovoltaïques en ligne",
          description:
            "Tout savoir sur comment mieux vendre avec nos présentations personnalisables, vos propositions sur-mesure de systèmes solaires, et notre méthode technico-financière.",
        },
        "dematerialised-administrative-management": {
          title: "Gestion administrative automatisée",
          description:
            "Tout savoir sur comment gagner du temps avec nos services numériques d’envoi et de suivi des démarches administratives obligatoires pour installations photovoltaïques.",
        },
        "contact-and-quotation-proposals": {
          title: "Mise en relation",
          description:
            "Tout savoir sur les signatures électroniques, les paiements automatiques sur internet, la mise en conformité, le service d’assistance de maîtrise d’ouvrage, et bien plus encore. ",
        },
        "materials-planning": {
          title: "Planification du matériel",
          description:
            "Tout savoir sur les listes de matériel de l’entreprise, kits personnalisables, approvisionnement et gestion du stock, comptes rendus de chantiers, et bien plus encore.",
        },
        "after-sales-service": {
          title: "Service après-installation",
          description:
            "Tout savoir sur la responsabilité de chacun, communication entre le maitre d’ouvrage et le maitre d’œuvre, l’entretien, le suivi de rendements, le dépannage et le nettoyage.",
        },
        "payments-pricing-and-refunds": {
          title: "Paiements, tarification et remboursements",
          description:
            "Marche à suivre pour percevoir de l’argent, gagner du chiffre d’affaires, acheter au travers de la boutique en ligne, et télécharger les factures comptables.",
        },
        "legal-notices": {
          title: "Mentions juridiques",
          description:
            "Informations sur la relation contractuelle entre nous et le professionnel photovoltaïque, avec aperçu des conditions générales mentions légales.",
        },
      },
      cta: {
        title: "Optez pour la technologie",
        description:
          "Notre technologie numérique vous permet de gérer collaborativement en équipe l'ensemble des étapes de vos projets solaires.",
        benefit: {
          benefit1: "Gestion des chantiers",
          benefit2: "Gestion d'agenda",
          benefit3: "Gestion du matériel",
          benefit4: "Paiements sécurisés",
          benefit5: "Signatures électroniques",
          benefit6: "Notifications email",
        },
        button: "Ouvrir un compte",
      },
    },
    "side-bar": {
      "bread-crumb": {
        home: "Accueil",
        help: "Centre d'aide",
        "your-account": "Votre compte",
        "manage-your-projects": "Gérez vos projets",
        presentation: "Étude",
        administration: "Administration",
        "quoting-and-invoicing": "Devis et facturation",
        "scheduling-your-hardware": "Planification de votre matériel",
        "post-installation": "Après l'installation",
        payments: "Paiements",
        legal: "Légal",
      },
      summary: "Sommaire",
      shortcut: {
        support: {
          title: "Contact support",
          description: "Prenez contact avec nos équipes",
          others: "Autres articles",
        },
      },
      search: "Rechercher...",
    },
  },
  "learn-centre": {
    header: {
      title: "Tout savoir sur le solaire",
    },
  },

  career: {
    home: {
      title: "Envie de faire quelque chose grand ?",
      description:
        "Rejoignez-nous pour aider tout le monde partout à accéder à l'énergie solaire - propre, gratuite et indépendante.",
    },
    values: {
      title: "Ce que nous recherchons",
      description:
        "Nous sommes une communauté d'engagement ou chacun partage et cultive l'audace, la perséverance et l'empathie dans notre petite équipe.",
      value1: {
        title: "Soyez les meilleurs",
        description:
          "Aspirez à l'excellence et atteignez des nouveaux sommets. Surmontez les défis avec détermination. Utilisez vos talents et recherchez la croissance. Incarnez le succès et l'amélioration continue.",
      },
      value2: {
        title: "Partagez tout ce que vous savez",
        description:
          "Adoptez l'échange de connaissances et la collaboration. Partagez votre expertise et vos idées. Créez un monde de connaissances accessibles. Cultivez la curiosité.",
      },
      value3: {
        title: "Toujours en apprentissage",
        description:
          "Soyez autodidacte ! Recherchez de nouvelles expériences. Envisagez les échecs comme des leçons. Adaptez-vous aux changements. Apprenez, grandissez et prospérez.",
      },
      value4: {
        title: "Soyez solidaire",
        description:
          "Créez un environnement bienveillant. Célébrez les réussites. Offrez votre aide. Favorisez l'empathie et les liens. Construisez la prospérité collective.",
      },
      value5: {
        title: "Assumez vos responsabilités",
        description:
          "Acceptez l'obligation de rendre des comptes. Assumez vos actes. Apprenez de vos erreurs. Reconnaissez l'impact de vos actions.",
      },
      value6: {
        title: "Vivez votre meilleure vie",
        description:
          "Reconnaissez l'importance du repos. Détendez-vous et rechargez vos batteries. Engagez-vous dans des activités joyeuses. Trouvez l'équilibre.",
      },
    },
    commitments: {
      FULL_TIME: "Temps plein",
      PART_TIME: "Temps partiel",
      INTERNSHIP: "Stage",
      CONTRACT: "Auto-entrepreneur",
      COFOUNDER: "Directeur⸱ice",
    },

    roles: {
      COFOUNDER: "Directeur⸱ice",
      PROGRAMMING: "Développeur⸱euse",
      CONTRACT: "Auto-entrepreneur",
      DESIGN: "Designeur⸱euse",
      MANAGEMENT: "Gérant⸱e",
      BUSINESS_DEVELOPMENT: "Développeur⸱euse d'affaires",
      SALES_AND_MARKETING: "Commercial⸱e",
      ADMINISTRATION: "Administratif",
    },
  },

  profile: {
    dropdown: {
      tickets: "Me tickets",
      settings: "Paramètres",
      "sign-out": "Déconnexion",
      type: {
        householder: "Propriétaire",
        installer: "Professionnel",
        admin: "Admin",
      },
    },
  },

  common,

  snackbars: {
    "lead-submitted": "Nous avons reçu votre demande",
    "password-reset-success": "Mot de passe mis à jour.",
    "reset-token-sent":
      "Vérifiez votre email pour mettre à jour votre mot de passe.",
    "register-success":
      "Vous avez créé votre compte projetsolaire ! Confirmez votre inscription avec le courriel dans votre boîte mail.",
    "login-success": "Bienvenue sur projetsolaire",
    "account-activated": "Votre compte a été activé avec succès.",
    success: {
      title: "Succès",
      common: "Informations sauvegardées avec succès",
    },
    errors: {
      title: "Alerte !",
      common:
        "Une erreur s’est produite. Veuillez réessayer puis nous contacter si le problème persiste.",
      failed:
        "Une erreur s’est produite. Veuillez réessayer puis nous contacter si le problème persiste.",
      "password-reset-failed": "La mise à jour du mot de passe a échouée.",
      "login-failed": "Connexion échouée : vérifier votre saisie.",
      "register-failed":
        "Nous n’avons pas pu créer votre compte. Veuillez réessayer puis nous contacter si le problème persiste.",
    },
  },
  "resale-agreements": {
    hero: {
      object: "Comparaison contrats d'achat",
      title: "Comparez les alternatives à EDF OA",
      description:
        "Explorez les différentes options pour vendre l'énergie produite par vos panneaux solaires. Découvrez les alternatives au contrat de rachat EDF OA et trouvez la solution qui correspond le mieux à vos besoins.",
    },
    offers: {
      "learn-more": "En savoir plus",
      "most-popular": "Le plus populaire",
      "electricity-grid": "Réseau électrique",
      "system-power": "Puissance",
      "consumption-client": "Consommation client",
      "rge-installer": "Installateur RGE",
      filters: {
        all: "Tous",
        "virtual-battery": "Batterie virtuelle",
        contract: "Contrat d'achat",
        "price-type": "Type de prix au kWh",
        "starting-fees": "Frais de dossier",
        "additional-fees": "Coûts d'abonnement",
        "electricity-grid": "Disponibilité",
        "system-power": "Puissances photovoltaïques",
        "consumption-client": "Obligation de consommer aussi",
        "requires-rge-installer": "Obligation d'une pose RGE",
      },

      cards: {
        "edf-oa-1": {
          provider: "EDF OA",
          title: "Obligation Achat (S21)",
          description:
            "Tarif d'achat garanti sur 20 ans par l'État en métropole.",
          "price-type": "Prix fixe",
          price: "0,1141 € - 0,1430 € / kWh	", //conditional on system power in kwc
          "starting-fees": "0 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 500 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Oui",
          },
        },
        "edf-oa-2": {
          provider: "EDF OA",
          title: "Obligation Achat (S24 en ZNI)",
          description:
            "Tarif d'achat garanti sur 20 ans par l'État en DROM-COM.",
          "price-type": "Prix fixe",
          price: "0,1141 € - 0,1430 € / kWh	", //conditional on system power in kwc
          "starting-fees": "0 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "DROM-COM",
            "system-power": "0 kWc - 500 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Oui",
          },
        },
        "3erl-1": {
          provider: "Association 3ERL",
          title: "Achat du surplus photovoltaïque",
          description:
            "Electricité Ecologique Economique Renouvelable et Locale.",
          "price-type": "Prix variable",
          price: "70% du prix SPOT",
          "starting-fees": "0 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 36 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Oui",
          },
        },
        "ekwateur-1": {
          provider: "Ekwateur",
          title: "Revente avec contrat Ekwateur",
          description:
            "Offre de rachat du surplus avec les avantages Ekwateur.",
          "price-type": "Prix variable",
          price: "0,06 € / kWh",
          "starting-fees": "0 €",
          "additional-fees": "1 € par kWc par mois",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 36 kWc",
            "consumption-client": "Oui",
            "requires-rge-installer": "Non",
          },
        },
        "ekwateur-2": {
          provider: "Ekwateur",
          title: "Revente sans contrat Ekwateur",
          description:
            "Offre de rachat du surplus sans les avantages Ekwateur.",
          "price-type": "Prix variable",
          price: "0,04 € / kWh",
          "starting-fees": "150 €",
          "additional-fees": "1 € par kWc par mois",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 36 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Non",
          },
        },
        "urban-solar-1": {
          provider: "Urban Solar Energy",
          title: "Boost Mon Surplus ≤ 36 KVA",
          description:
            "Revente du surplus pour particuliers, sans engagement, sans RGE.",
          "price-type": "Prix variable",
          price: "0,03 € / kWh",
          "starting-fees": "249 € TTC",
          "additional-fees": "1 € par kWc par mois",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 36 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Non",
          },
        },
        "urban-solar-2": {
          provider: "Urban Solar Energy",
          title: "Boost Mon Surplus > 36 KVA",
          description:
            "Revente du surplus pour professionnels, sans engagement, sans RGE.",
          "price-type": "Prix variable",
          price: "0,03 € / kWh",
          "starting-fees": "650 € TTC",
          "additional-fees": "1 € par kWc par mois",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "36 kWc - 250 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Non",
          },
        },
        "jpme-1": {
          provider: "JPME",
          title: "E-Surplus Fixe Particuliers",
          description: "Tarif d'achat fixé à vie pour les particuliers.",
          "price-type": "Prix fixe",
          price: "0,08 € / kWh",
          "starting-fees": "499 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 36 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Non",
          },
        },
        "jpme-2": {
          provider: "JPME",
          title: "E-Surplus Flex Particuliers",
          description:
            "Tarif évolue en fonction des fluctuations du marché SPOT.",
          "price-type": "Prix variable",
          price: "Inconnu",
          "starting-fees": "649 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 36 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Non",
          },
        },
        "jpme-3": {
          provider: "JPME",
          title: "E-Surplus Fixe Professionnels",
          description: "Tarif d'achat fixé à vie pour les particuliers.",
          "price-type": "Prix fixe",
          price: "0,08 € / kWh",
          "starting-fees": "990 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "36 kWc - 250 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Non",
          },
        },
        "jpme-4": {
          provider: "JPME",
          title: "E-Surplus Flex Professionnels",
          description:
            "Tarif évolue en fonction des fluctuations du marché SPOT.",
          "price-type": "Prix variable",
          price: "Inconnu",
          "starting-fees": "990 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "36 kWc - 250 kWc",
            "consumption-client": "Non",
            "requires-rge-installer": "Non",
          },
        },
        "my-light-1": {
          provider: "My Light Systems",
          title: "Mysmart PPA",
          description:
            "Le Power Purchase Agreement (PPA) réinventé pour particuliers aussi.",
          "price-type": "Inconnu",
          price: "Inconnu",
          "starting-fees": "Inconnu",
          "additional-fees": "Inconnu",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "Inconnu",
            "consumption-client": "Inconnu",
            "requires-rge-installer": "Inconnu",
          },
        },
        "octopus-energy-1": {
          provider: "Octopus Energy",
          title: "Octopus Energy Résidentiel",
          description: "Offre pilote chez Octopus d'achat votre énergie.",
          "price-type": "Prix fixe",
          price: "0,10 € / kWh",
          "starting-fees": "0 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 36 kWc",
            "consumption-client": "Oui",
            "requires-rge-installer": "Non",
          },
        },
        "octopus-energy-2": {
          provider: "Octopus Energy",
          title: "Octopus Energy Tertiaire",
          description:
            "Tarifs d'achat personnalisés pour les producteurs puissants.",
          "price-type": "Inconnu",
          price: "Inconnu",
          "starting-fees": "Inconnu",
          "additional-fees": "Inconnu",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "36 kWc - 250 kWc",
            "consumption-client": "Oui",
            "requires-rge-installer": "Non",
          },
        },
        "mint-energie-1": {
          provider: "Mint Énergie",
          title: "Rachat d'électricité par Mint Énergie",
          description:
            "Actuellement en refonte de nos offres de rachat de surplus solaire.",
          "price-type": "Inconnu",
          price: "Inconnu",
          "starting-fees": "Inconnu",
          "additional-fees": "Inconnu",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "Inconnu",
            "consumption-client": "Inconnu",
            "requires-rge-installer": "Inconnu",
          },
        },
        "total-energies-1": {
          provider: "Total Energies",
          title: "Contrat d'achat Total Energies",
          description: "Contrat d'achat hors obligation achat.",
          "price-type": "Inconnu",
          price: "Inconnu",
          "starting-fees": "Inconnu",
          "additional-fees": "Inconnu",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "Inconnu",
            "consumption-client": "Inconnu",
            "requires-rge-installer": "Inconnu",
          },
        },
        "enercoop-1": {
          provider: "Enercoop",
          title: "Producteurs moins de 200 MWh/an",
          description:
            "Offre hors obligation achat pour petits collectifs de citoyens ou collectivités.",
          "price-type": "Prix fixe",
          price: "0,065 € / kWh",
          "starting-fees": "0 €",
          "additional-fees": "0 €",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "0 kWc - 36 kWc",
            "consumption-client": "Oui",
            "requires-rge-installer": "Non",
          },
        },
        "enercoop-2": {
          provider: "Enercoop",
          title: "Producteurs plus de 200 MWh/an",
          description:
            "Offre hors obligation achat pour grands collectifs de citoyens ou collectivités.",
          "price-type": "Inconnu",
          price: "Inconnu",
          "starting-fees": "Inconnu",
          "additional-fees": "Inconnu",
          eligibility: {
            "electricity-grid": "Métropole",
            "system-power": "36 kWc - 250 kWc",
            "consumption-client": "Oui",
            "requires-rge-installer": "Non",
          },
        },
      },
    },
    process: {
      radios: {
        "new-project": "Nouveau projet",
        "switch-form-current-contract": "Changement de contrat",
      },
      "new-project": {
        title:
          "Comment vendre son surplus pour valoriser sa production d'électricité solaire ?",
        description:
          "Vente du surplus ou vente en totalité, contrat d'achat ou bien batterie virtuelle - pensez à améliorer la rentabilité de votre investissement.",
        timeline: {
          decision: {
            title: "1. Choisissez votre modèle économique",
            description:
              "Étudiez les alternatives et leur pertinence par rapport à votre gisement solaire et votre consommation d'électricité.",
          },
          install: {
            title: "2. Réalisez la pose avec un professionnel RGE",
            description:
              "Obtenez plusieurs devis pour évaluer les offres et choisissez un poseur sans sous-traitance avec label RGE.",
          },
          enedis: {
            title: "3. Réalisez rapidement la demande de raccordement",
            description:
              "Assurez-vous de la bonne réalisation de la demande de raccordement auprès d'Enedis (ou régie locale) pour sécuriser le dossier.",
          },
          "finish-grid-connection": {
            title:
              "4. Obtenez la mise en service de votre installation photovoltaïque",
            description:
              "Réduisez les délais avec un excellent suivi du dossier et faites-vous faire l'attestation de conformité du Consuel.",
          },
          sign: {
            title: "5. Signez avec le EDF OA ou autre co-contractant",
            description:
              "Démarrez votre expérience solaire sur le bon pied dès le premier jour avec un contrat qui rentabilise chaque kWh produit.",
          },
        },
      },
      "switch-from-current-contract": {
        title: "Comment changer de contrat d'achat ?",
        description: "",
        // Maarten said we have to ask Naina for this timeline
        timeline: {},
      },
    },
    CTA: {
      title:
        "Démarrez votre projet solaire dès aujourd'hui avec le contrat d'achat de votre choix",
      description: "",
      button: "Commencez aujourd'hui",
    },
  },
  "hardware-marketplace": {
    hero: {
      "whats-new": "Quoi de neuf ?",
      "new-material": "Nouveau matériel",
      title: "Acheter votre matériel n'a jamais été aussi simple",
      description:
        "Demandez des devis personnalisés, accédez à des produits disponibles pour un achat immédiat, suivez vos livraisons en temps réel et profitez de notre programme d’achat groupé pour des économies garanties !",
      "try-now": "Essayer maintenant",
    },
    values: {
      object: "Fonctionnalités",
      title: "Nos services",
      description:
        "Quis tellus eget adipiscing convallis sit sit eget aliquet quis. Suspendisse eget egestas a elementum pulvinar et feugiat blandit at. In mi viverra elit nunc.",
      features: {
        quote: {
          title: "Demande de devis auprès des fournisseurs",
          description:
            "Simplifiez le processus de demande de devis en envoyant directement vos besoins à plusieurs fournisseurs et obtenez des propositions adaptées à votre projet.",
        },
        buying: {
          title: "Accès à des produits pour achat immédiat :",
          description:
            "Explorez une sélection de produits disponibles à l'achat immédiat, sans attente ni formalités, pour répondre rapidement à vos besoins en matériel.",
        },
        delivery: {
          title: "Suivi des livraisons",
          description:
            "Gardez un œil en temps réel sur l'état de vos commandes et suivez chaque étape de la livraison jusqu'à la réception de votre matériel.",
        },
        "group-buying": {
          title: "Programme d'achat groupé",
          description:
            "Profitez de réductions avantageuses en rejoignant notre programme d'achat groupé, permettant de réaliser des économies substantielles sur vos commandes grâce à des achats en volume.",
        },
        integration: {
          title: "Intégration facile du matériel de vos projets",
          description:
            "Facilitez l'intégration de votre nouveau matériel dans vos projets avec des solutions conçues pour une adaptation rapide et sans effort à vos systèmes existants.",
        },
        historic: {
          title: "Historique des commandes",
          description:
            "Accédez à un historique complet de vos commandes passées pour suivre vos achats et gérer vos stocks plus efficacement.",
        },
      },
    },
    reservations: {
      title: "Les Avantages de l’Achat Groupé de Matériel Photovoltaïque",
      description:
        "L'achat groupé de matériel photovoltaïque est une pratique de plus en plus prisée pour ses nombreux avantages. En regroupant les commandes, cette méthode permet non seulement de réaliser des économies substantielles, mais aussi de simplifier le processus d'achat pour tous les participants.",
      advantages: {
        title:
          "Voici pourquoi cette approche est avantageuse et comment elle fonctionne en trois étapes simples.",
        price: {
          title: "Réduction des Coûts",
          description:
            "L'achat groupé permet de réduire considérablement les coûts en négociant des tarifs préférentiels grâce à des commandes combinées. Cela rend les équipements photovoltaïques plus abordables pour les participants.",
        },
        quality: {
          title: "Accès à des Produits de Qualité",
          description:
            "L'achat groupé offre des produits de haute qualité à prix réduits. Les fournisseurs privilégient les commandes groupées en proposant des équipements fiables et performants.",
        },
        simplicity: {
          title: "Simplification du Processus d'Achat",
          description:
            "L'achat groupé simplifie la gestion en centralisant les commandes et réduisant les transactions individuelles. Cela facilite le suivi et la réception du matériel pour les participants.",
        },
        conclusion:
          "En conclusion, l'achat groupé de matériel photovoltaïque est une solution avantageuse qui permet de réaliser des économies importantes tout en simplifiant le processus d'acquisition. En suivant les trois étapes clés de participation, paiement, et suivi, vous pouvez bénéficier des meilleurs tarifs tout en assurant une gestion efficace de votre achat.",
      },
    },
    checkout: {
      title: "Catalogue de +10000 modèles",
      "see-more": "Voir toute la sélection",
      "database":"Base de donnée"
    },
    simplicity: {
      object: "Optimisation",
      title: "Intégration facile du matériel prévu dans vos projets",
      paragraph1:
        "Notre solution facilite l'intégration du matériel prévu dans vos projets dès la phase de planification. Vous pouvez attribuer le matériel à différents projets ou devis de vos clients même avant sa réception.",
      paragraph2:
        "Grâce à notre interface intuitive, vous gérez facilement l'affectation du matériel aux projets en cours, ce qui simplifie la préparation et l'organisation de vos projets.",
      paragraph3:
        "Cette flexibilité permet une meilleure gestion des ressources et une planification plus efficace, tout en assurant que chaque projet dispose des équipements nécessaires dès que les besoins sont définis.",
      "try-now": "Essayez maintenant",
    },
    logistics: {
      object: "Livraison",
      title: "Suivi de vos livraisons en temps réel",
      description:
        "Notre service de suivi vous permet de surveiller vos livraisons de matériel photovoltaïque en temps réel. Grâce à notre système de traçabilité avancé, vous recevez des mises à jour instantanées sur l'état de votre commande, depuis l'expédition jusqu'à la livraison finale. Vous pouvez consulter l'emplacement actuel de votre matériel, prévoir les délais de réception, et être informé de toute éventuelle modification ou retard. Ce suivi transparent et précis vous offre une tranquillité d'esprit, vous permettant de mieux planifier l'installation de vos équipements photovoltaïques et d’assurer une gestion efficace de votre projet.",
      timeline: {
        step1: "Préparation et emballage du matériel pour l'expédition.",
        step2: "Expédition du matériel par l'expéditeur.",
        step3: "Transport du matériel vers le centre de distribution.",
        step4: "Traitement du matériel au centre de distribution.",
        step5: "Livraison du matériel à l'adresse de destination.",
        step6: "Confirmation de réception.",
      },
    },
    "data-driven":{
      "down-stream-sales-title":"Visibilité pour vos ventes en aval",
      "down-stream-sales-desc":"Notre solution facilite l'intégration du matériel prévu dans vos projets dès la phase de planification. Vous pouvez attribuer le matériel à différents projets ou devis de vos clients même avant sa réception. Grâce à notre interface intuitive, vous gérez facilement l'affectation du matériel aux projets en cours, ce qui simplifie la préparation et l'organisation de vos projets. Cette flexibilité permet une meilleure gestion des ressources et une planification plus efficace, tout en assurant que chaque projet dispose des équipements nécessaires dès que les besoins sont définis.",
      "purchase-suggestion-title":"Suggestion d'achat",
      "purchase-suggestion-desc": "Notre solution facilite l'intégration du matériel prévu dans vos projets dès la phase de planification. Vous pouvez attribuer le matériel à différents projets ou devis de vos clients même avant sa réception. Grâce à notre interface intuitive, vous gérez facilement l'affectation du matériel aux projets en cours, ce qui simplifie la préparation et l'organisation de vos projets. Cette flexibilité permet une meilleure gestion des ressources et une planification plus efficace, tout en assurant que chaque projet dispose des équipements nécessaires dès que les besoins sont définis.",
      "human-in-loop-title":"Humain dans la boucle",
      "human-in-loop-desc":"Notre solution facilite l'intégration du matériel prévu dans vos projets dès la phase de planification. Vous pouvez attribuer le matériel à différents projets ou devis de vos clients même avant sa réception. Grâce à notre interface intuitive, vous gérez facilement l'affectation du matériel aux projets en cours, ce qui simplifie la préparation et l'organisation de vos projets. Cette flexibilité permet une meilleure gestion des ressources et une planification plus efficace, tout en assurant que chaque projet dispose des équipements nécessaires dès que les besoins sont définis.",
      "data-driven":"Piloté par les données",
      "coming-soon":"Coming soon",
    }
  },
}
