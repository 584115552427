export default {
  heading: "Type de projet",
  carport: "Carport",
  "flat-roof": "Toit plat",
  "ground-mount": "Montage au sol",
  "off-roof": "Hors toit",
  "on-roof": "Sur toit",
  pergola: "Sur Pergola",
  tiles: "Tuiles solaires",
}
