export default {
  "input-form": {
    title: "Titre",
    description: "Description",
    "write-few-sentence": "Décrivez en quelques phrases la demande de pièces.",
    "issued-at": "Demande émise le ",
    "supplementary-information": "Informations supplémentaires",
    subtitle2:
      "Utilisez une adresse permanente où vous pouvez recevoir du courrier.",
    "admin-service": "Démarche administrative",
    media: "Médias",
  },
}
