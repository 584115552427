import resaleAgreements from "./resale-agreements"
import timeline from "./timeline"
import types from "./types"
import revisions from "./revisions"

export default {
  "resale-agreements": resaleAgreements,
  revisions,
  timeline,
  types,
  mayor: {
    contactCard: {
      website: "Site web",
      plans: "Plans",
      phone: "Telephone du standard",
      email: "Email du standard",
      "urban-planning-phone": "Telephone du service d'urbanisme",
      "urban-planning-email": "Email du service d'urbanisme",
    },
  },
}
